import * as ActionType from '../Constants/ActionsTypes'
import dumpRDM from "../Assets/dump.json"
import dumpUK from "../Assets/dump-uk.json"


export default class Recos {
    logic: any;
    productsDB: any;
    panier: Array<Array<any>>;
    panier1: Array<any>;
    panier2: Array<any>;
    panier3: Array<any>;
    kitProducts: Array<any>;
    questionNames: Array<string>;

    constructor() {
        this.logic = require('../Assets/logic (1).json')
        this.productsDB = null
        this.panier = [[], [], []]
        this.panier1 = []
        this.panier2 = []
        this.panier3 = []
        this.kitProducts = [[], [], []]
        this.questionNames = ["concern", "routine", "sensitivity", "environnement", "weakness", "cleanserWater", "age", "todayHealth", "skinfeel"]
    }

    castConcernName(key: string): string {
        switch (key) {
            case "eyebags":
                return "EYES_BAGS"
            case "eyes-wrinkles":
                return "WRINKLES_UNDER_EYE"
            case "blackspot":
                return "ACNE"
            default:
                return key.toUpperCase()
        }
    }

    castConcernNameToLowerCase(key: string): string {
        switch (key) {
            case "WRINKLES_UNDER_EYE":
                return "eyes-wrinkles"
            case "EYES_BAGS":
                return "eyebags"
            case "LOWER_FACE_PTOSIS":
                return "firmness"
            case "HOLLOW_CHEEKS":
                return "firmness"
            case "TEAR_THROUGH":
                return "firmness"
            case "DARK_SPOTS":
                return "darkspots"
            case "DARK_CIRCLES":
                return "eyebags"
            case "BLACK_SPOTS":
                return "blackspot"
            case "HYDRATATION":
                return "hydration"
            default:
                return key.toLowerCase()
        }
    }

    getReco(analyses: Array<any>, questions: Array<any>, dispatch: any): any {
        analyses.sort((a, b) => {
            return b.score - a.score
        })

        this.logic.recommendation.forEach((e: any) => {
            let conditionCount = 0
            if (e.panier1) {
                // rank des concerns
                if (e.panier1.conditions[0].rank && this.panier1.length < 4) {
                    Object.keys(e.panier1.conditions[0].rank).forEach((el: any) => {
                        if (el === "wrinkles" && analyses[0].name === "WRINKLES_UNDER_EYE") {
                            this.panier1.push(e)
                        }
                        else if (el === this.castConcernNameToLowerCase(analyses[0].name)) {
                            this.panier1.push(e)
                        }
                    })
                }

                if (e.panier1.conditions[0].question) {
                    // cas des supercharged
                    if (e.name.includes("Supercharged")) {
                        e.panier1.conditions[0].question.concern.forEach((concern: string) => {
                            if (concern === questions[5][0] && this.castConcernNameToLowerCase(concern) !== this.castConcernNameToLowerCase(analyses[0].name) && !this.panier1.includes(e)) {

                                this.panier1.push(e)
                            }
                            else if (concern === questions[5][1] && this.castConcernNameToLowerCase(questions[5][0]) === this.castConcernNameToLowerCase(analyses[0].name) && !this.panier1.includes(e)) {

                                this.panier1.push(e)
                            }

                        })
                    }
                    // cas des nutri
                    else if (e.name.includes("Nutri")) {
                        e.panier1.conditions[0].question.concern.forEach((concern: string) => {
                            if (concern === questions[5][0]) {
                                this.panier1.push(e)
                            }
                        })
                    }
                    else {
                        this.questionNames.forEach((concernName: string) => {
                            if (e.panier1.conditions[0].question[concernName]) {
                                e.panier1.conditions[0].question[concernName].forEach((el: any) => {
                                    if (questions.flat().includes(el)) {
                                        this.panier1.push(e)
                                    }
                                })
                            }
                        })
                    }
                }

            }

            //panier 2
            if (e.panier2) {

                // console.log("")
                // console.log("checking " + e.name)
                let valide: boolean = false //check si condition est valide

                if (e.panier2.conditions) {
                    e.panier2.conditions.forEach((condition: any) => {
                        if (condition.rank) {
                            valide = Object.keys(e.panier2.conditions[0].rank).some((el: any) => {
                                return el === analyses[0].name.toLowerCase()
                            })
                        }

                        if (condition.question) {
                            if (condition.question.exclude) {
                                this.questionNames.forEach((concernName: string) => {
                                    if (condition.question.exclude[concernName]) {
                                        valide = !condition.question.exclude[concernName].some((el: any) => {
                                            return questions.flat().includes(el)
                                        })
                                    }
                                })
                            }
                            else {
                                this.questionNames.forEach((concernName: string) => {
                                    if (condition.question[concernName]) {
                                        valide = condition.question[concernName].some((el: any) => {
                                            return questions.flat().includes(el)
                                        })
                                    }
                                })
                            }
                        }
                        if (condition.score) {
                            for (let [key] of Object.entries(condition.score)) {
                                if (analyses.find((analyse: any) => analyse.name === this.castConcernName(key))) {
                                    // cas particulier du concern firmness
                                    if (key === "firmness") {
                                        if (condition.score[key] <= analyses.find((analyse: any) =>
                                            analyse.name === "HOLLOW_CHEEKS" ||
                                            analyse.name === "TEAR_THROUGH" ||
                                            analyse.name === "LOWER_FACE_PTOSIS"
                                        ).score) {
                                            //this.panier2.push(e)
                                            valide = true
                                            //console.log("+1 rank")
                                        }
                                    }

                                    if (condition.score[key] <= analyses.find((analyse: any) => analyse.name === this.castConcernName(key)).score) {
                                        //this.panier2.push(e)
                                        valide = true
                                        //console.log("+1 rank")
                                    }
                                }
                            }
                        }

                        if (valide) {
                            conditionCount++
                        }
                    })
                    if (conditionCount === e.panier2.conditions.length && e.panier2.conditions.length > 1) { //si toutes les conditions sont valides, on recommende
                        this.panier2.push(e)
                    }
                    else if (conditionCount > 0 && e.panier2.conditions.length === 1) {
                        this.panier2.push(e)
                    }
                }

            }

            conditionCount = 0;
            if (e.panier3 && !this.panier2.includes(e)) {
                if (e.sku === "MB0019") {
                    this.panier3.push(e)
                }
                let valide: boolean = false //check si condition est valide
                if (e.panier3.conditions) {
                    e.panier3.conditions.forEach((condition: any) => {
                        if (condition.rank) {
                            Object.keys(e.panier3.conditions[0].rank).forEach((el: any) => {
                                if (el === analyses[0].name.toLowerCase()) {
                                    valide = true
                                }
                            })
                        }

                        if (condition.question) {
                            if (condition.question.exclude) {
                                this.questionNames.forEach((concernName: string) => {
                                    if (condition.question.exclude[concernName]) {
                                        condition.question.exclude[concernName].forEach((el: any) => {
                                            if (questions.flat().includes(el)) {
                                                valide = false
                                            }
                                        })
                                    }
                                })
                            }
                            else {
                                this.questionNames.forEach((concernName: string) => {
                                    if (condition.question[concernName]) {
                                        condition.question[concernName].forEach((el: any) => {
                                            if (questions.flat().includes(el)) {
                                                valide = true
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        if (condition.score) {
                            for (let [key] of Object.entries(condition.score)) {
                                if (analyses.find((analyse: any) => analyse.name === this.castConcernName(key))) {
                                    // cas particulier du concern firmness
                                    if (key === "firmness") {
                                        if (condition.score[key] <= analyses.find((analyse: any) =>
                                            analyse.name === "HOLLOW_CHEEKS" ||
                                            analyse.name === "TEAR_THROUGH" ||
                                            analyse.name === "LOWER_FACE_PTOSIS"
                                        ).score) {
                                            valide = true
                                        }
                                    }

                                    if (condition.score[key] <= analyses.find((analyse: any) => analyse.name === this.castConcernName(key)).score) {
                                        valide = true
                                    }
                                }
                            }
                        }

                        if (valide) { conditionCount++ }
                    })
                    if (conditionCount === e.panier3.length && e.panier3.length > 1) { //si toutes les conditions sont valides, on recommende
                        this.panier3.push(e)
                    }
                    else if (conditionCount > 0) {
                        this.panier3.push(e)
                    }
                }

            }
        });



        let uniqueRecoPanier2 = this.panier2.filter((v, i, a) => a.indexOf(v) === i);
        let uniqueRecoPanier1 = this.panier1.filter((v, i, a) => a.indexOf(v) === i);
        let uniqueRecoPanier3 = this.panier3.filter((v, i, a) => a.indexOf(v) === i);

        // le pre-serum est toujours recommandé
        uniqueRecoPanier2.push({ sku: "80086659", name: "pre-serum" })

        if (uniqueRecoPanier1.length < 4) {
            this.logic.recommendation.forEach((product: any) => {
                if (product.name.includes("Supercharged")) {
                    product.panier1.conditions[0].question.concern.forEach((concern: string) => {
                        if (concern === this.castConcernNameToLowerCase(analyses[0].name) && !this.panier1.includes(product)) {
                            uniqueRecoPanier1.push(product)
                        }
                    })
                }
            });
        }
        if (uniqueRecoPanier1.length < 4) {
            this.logic.recommendation.forEach((product: any) => {
                if (product.name.includes("Supercharged")) {
                    product.panier1.conditions[0].question.concern.forEach((concern: string) => {
                        if (concern === this.castConcernNameToLowerCase(analyses[1].name)) {
                            uniqueRecoPanier1.push(product)
                        }
                    })
                }
            });
        }

        if (uniqueRecoPanier1.length < 4) {
            this.logic.recommendation.forEach((product: any) => {
                if (product.name.includes("Supercharged")) {
                    product.panier1.conditions[0].question.concern.forEach((concern: string) => {
                        if (concern === this.castConcernNameToLowerCase(analyses[2].name) && !uniqueRecoPanier1.includes(product)) {
                            uniqueRecoPanier1.push(product)
                        } else if (concern === this.castConcernNameToLowerCase(analyses[3].name) && !uniqueRecoPanier1.includes(product)) {
                            uniqueRecoPanier1.push(product)
                        }
                    })
                }
            });
        }

        console.warn(uniqueRecoPanier2)
        if (this.panier1.length < 4) {
            this.logic.recommendation.forEach((e: any) => {
                if (e.panier1) {
                    // rank des concerns
                    if (e.panier1.conditions[0].rank) {
                        Object.keys(e.panier1.conditions[0].rank).forEach((el: any) => {
                            if (el === "wrinkles" && analyses[1].name === "WRINKLES_UNDER_EYE") {
                                this.panier1.push(e)
                            }
                            else if (el === this.castConcernNameToLowerCase(analyses[1].name)) {
                                this.panier1.push(e)
                            }
                        })
                    }

                    if (e.panier1.conditions[0].question) {
                        // cas des supercharged
                        if (e.name.includes("Supercharged")) {
                            e.panier1.conditions[0].question.concern.forEach((concern: string) => {
                                if (e.panier1.length < 4) {
                                    if (concern === questions[5][0] && this.castConcernNameToLowerCase(concern) !== this.castConcernNameToLowerCase(analyses[0].name) && !this.panier1.includes(e)) {
                                        this.panier1.push(e)
                                    }
                                    else if (concern === questions[5][1] && this.castConcernNameToLowerCase(questions[5][1]) === this.castConcernNameToLowerCase(analyses[0].name) && !this.panier1.includes(e)) {
                                        this.panier1.push(e)
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }

        let panierString: string = ""

        this.panier.forEach((panierIn: Array<any>) => {
            panierIn.forEach((product) => {
                panierString += product.sku + ","
            })
        })

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", "73a6195f-d489-4a5f-8658-0a8ae61ce386");
        urlencoded.append("sku", panierString)
        var replaced = false;

        // let isQA: boolean = document.URL.includes("qa-")
        // let url = isQA ? "https://myblend.wakemeup-dns.com/wp-json/myblend-api/v1" : "https://my-blend.com/wp-json/myblend-api/v1"
        // if (window.market === "uk") {
        //     url = isQA ? "https://myblend-uk.wakemeup-dns.com/wp-json/myblend-api/v1" : "https://my-blend.co.uk/wp-json/myblend-api/v1"
        // }

        // let url: string = "https://my-blend.com/wp-json/myblend-api/v1" // https://my-blend.com/wp-json/myblend-api/v1
        // if(window.market === "uk"){
        //     url = "https://my-blend.co.uk/wp-json/myblend-api/v1" //https://my-blend.co.uk/wp-json/myblend-api/v1
        // }
        let dump: any = null

        if (window.market === "uk") {
            dump = dumpUK
        } else {
            dump = dumpRDM
        }

        uniqueRecoPanier1.forEach((e: any) => {
            if (dump.find((product: any) => product.sku === e.sku)) {
                if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                    if (this.kitProducts[0].length < 3) {
                        if (!this.kitProducts[0].includes(dump.find((product: any) => product["associatedKitProduct "] === e.sku))) {
                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                        }
                    }
                }
                else {
                    if (e.sku === "80086679") {
                        this.logic.recommendation.forEach((e: any) => {
                            //REMPLACEMENT PAR LES PROCHAINES REPONSES A LA QUESTION CONCERN
                            if (e.name.includes("Supercharged")) {
                                e.panier1.conditions[0].question.concern.forEach((concern: string) => {
                                    if (this.kitProducts[0].length < 3 && !replaced) {
                                        if (concern === questions[5][0] && questions[5][0] !== "firmness" && this.castConcernNameToLowerCase(concern) !== this.castConcernNameToLowerCase(analyses[0].name) && !this.kitProducts[0].includes(dump.find((product: any) => product["associatedKitProduct "] === e.sku))) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                        else if (concern === questions[5][1] && questions[5][1] !== "firmness" && this.castConcernNameToLowerCase(concern) !== this.castConcernNameToLowerCase(analyses[0].name) && !this.kitProducts[0].includes(dump.find((product: any) => product["associatedKitProduct "] === e.sku))) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                        else if (concern === questions[5][2] && questions[5][2] !== "firmness" && this.castConcernNameToLowerCase(questions[5][2]) !== this.castConcernNameToLowerCase(analyses[0].name) && !this.kitProducts[0].includes(dump.find((product: any) => product["associatedKitProduct "] === e.sku))) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                    }
                                })
                            }
                            //}
                        })
                    }
                }
                this.panier[0].push(dump.find((product: any) => product.sku === e.sku))
            } else {
                console.log("Product ", e.sku, " recommanded but not given by the API")
            }
        })
        //REMPLACEMENT PAR LE SCORE
        if (!replaced) {
            uniqueRecoPanier1.forEach((e: any) => {
                this.logic.recommendation.forEach((e: any) => {
                    if (e.panier1) {
                        if (e.panier1.conditions[0].rank) {
                            Object.keys(e.panier1.conditions[0].rank).forEach((el: any) => {
                                if (!replaced && this.kitProducts[0].length < 3) {
                                    if (el === "wrinkles" && analyses[1].name === "WRINKLES_UNDER_EYE") {
                                        if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                    }
                                    else if (el === this.castConcernNameToLowerCase(analyses[1].name)) {
                                        if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                    }
                                    else if (el === "wrinkles" && analyses[2].name === "WRINKLES_UNDER_EYE") {
                                        if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                    }
                                    else if (el === this.castConcernNameToLowerCase(analyses[2].name)) {
                                        if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                                            replaced = true
                                            this.kitProducts[0].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            })
        }
        uniqueRecoPanier2.forEach((e: any) => {
            if (dump.find((product: any) => product.sku === e.sku && e.sku !== "80086663")) {
                if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                    this.kitProducts[1].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                }
                this.panier[1].push(dump.find((product: any) => product.sku === e.sku))
            } else {
                console.log("Product ", e.sku, " recommanded in panier 2 but not given by the API")
            }
        })

        uniqueRecoPanier3.forEach((e: any) => {
            if (dump.find((product: any) => product.sku === e.sku)) {
                if (dump.find((product: any) => product["associatedKitProduct "] === e.sku)) {
                    this.kitProducts[2].push(dump.find((product: any) => product["associatedKitProduct "] === e.sku))
                }
                this.panier[2].push(dump.find((product: any) => product.sku === e.sku))
            } else {
                console.log("Product ", e.sku, " recommanded but not given by the API")
            }
        })
        console.log("Productkit : " + this.kitProducts)

        dispatch({ type: ActionType.FILL_RECOMMENDED_PRODUCTS, value: this.panier })
        dispatch({ type: ActionType.FILL_RITUAL_KIT, value: this.kitProducts })
        return this.panier

    }
}
