export const SET_STEP = 'SET_STEP'
export const ANALYSE_PHOTO = 'ANALYSE_PHOTO'
export const ANALYSE_UPDATE = 'ANALYSE_UPDATE'
export const ANALYSE_BIOMETRICS_DONE = 'ANALYSE_BIOMETRICS_DONE'
export const ANALYSE_DONE = 'ANALYSE_DONE'
export const RECOMMENDATION_UPDATE = 'RECOMMENDATION_UPDATE'
export const ANALYSE_ERROR = 'ANALYSE_ERROR'
export const SET_QUESTION = 'SET_QUESTION' // save les questions dans le store
export const FILL_RECOMMENDED_PRODUCTS = "FILL_RECOMMENDED_PRODUCTS"
export const FILL_RITUAL_KIT = "FILL_RITUAL_KIT"
export const RESET_EXPERIENCE = "RESET_EXPERIENCE"
export const UPDATE_RITUAL_KIT = "UPDATE_RITUAL_KIT"
export const SET_ACTUAL_CONCERN = "SET_ACTUAL_CONCERN"
export const SET_ACTUAL_CONCERN_NAME = "SET_ACTUAL_CONCERN_NAME"
export const SET_ANALYTIC_OBJECT = "SET_ANALYTIC_OBJECT"
export const UPDATE_MODEL = "UPDATE_MODEL"