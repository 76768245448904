import { Quest } from "../Constants/QuestsConstants"
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export class AnalyticsObject {
    firebaseConfig: any
    analytics: any

    constructor() {
        this.firebaseConfig = {
            apiKey: "AIzaSyAAwzPpD1kDBac8bESiWrTzARzykaXizQM",
            authDomain: "myblend-skindiag.firebaseapp.com",
            databaseURL: "https://myblend-skindiag.firebaseio.com",
            projectId: "myblend-skindiag",
            storageBucket: "myblend-skindiag.appspot.com",
            messagingSenderId: "670052011054",
            appId: "1:670052011054:web:b30ce8982fa747409763bf",
            measurementId: "G-WWLRGS1M9M"
        }
        const app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(app);
    }

    sendAnalyticsEvent = (name: string, params: any) => {

        let analyticEvent = {
            "messageType": "analytics_event",
            "event_category": "MySkinDiag",
            "event_action": name,
            "parameters": params,
        }

        console.log("[ANALYTICS:]")
        console.log(analyticEvent)
        window.parent.postMessage(analyticEvent, "*")
        logEvent(this.analytics, name, params)
    }

    sendAnalyticsEventForQuest = (questions: Array<any>) => {
        let i: number = 0

        Object.keys(Quest).filter((v) => isNaN(Number(v))).forEach(quest => {
            if (quest !== "done" && questions[i] !== "tweak") {
                this.sendAnalyticsEvent("question_answer", { [quest]: Array.isArray(questions[i]) ? questions[i].join(' ') : questions[i] })
            }
            i++
        })
    }

    sendAnalyticsEventForScores = (analyses: Array<any>) => {

        const setAnalyticsName = (analyseName: string): string => {
            switch (analyseName) {
                case "WRINKLES":
                    return "wrinkles"
                case "WRINKLES_UNDER_EYE":
                    return "eye-wrinkles"
                case "REDNESS":
                    return "redness"
                case "SHININESS":
                    return "shininess"
                case "EYES_BAGS":
                case "DARK_CIRCLES":
                    return "eyebags"
                case 'RADIANCE':
                    return "radiance"
                case "DARK_SPOTS":
                    return "darkspots"
                case "ACNE":
                    return "spots"
                case "PORES":
                    return "pores"
                case "TEAR_THROUGH":
                case "LOWER_FACE_PTOSIS":
                case "HOLLOW_CHEEKS":
                    return "firmness"
                case "HYDRATATION":
                    return "hydration"
                default:
                    return "erreur dans setAnalyticName"
            }
        }

        const setScoreFactor = (analyseName: string): number => {
            switch (analyseName) {
                case "RADIANCE":
                    return 1.2
                case "REDNESS":
                    return 1.23
                case "SHININESS":
                    return 5.49
                case 'WRINKLES_UNDER_EYE':
                    return 1.31
                default:
                    return 1
            }
        }

        analyses.forEach((analyse: any) => {
            this.sendAnalyticsEvent("analysis_result", { [setAnalyticsName(analyse.name)]: Math.round(analyse.score * 100) / 100, ["factor_" + setAnalyticsName(analyse.name)]: setScoreFactor(analyse.name) })
        })
    }

    sendAnalyticsEventForSku = (skus: Array<any>, sku: string, isKit: boolean = false) => {
        if (isKit) {
            this.sendAnalyticsEvent("reco_product", { "kit": sku })
        } else {
            for (let i = 0; i < skus.length; i++) {
                if (skus[i].some((e: any) => e.sku === sku)) {
                    this.sendAnalyticsEvent("reco_product", { ["panier" + (i + 1).toString()]: sku })
                }
            }
        }

    }
}

