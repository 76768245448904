import { ComponentContainer } from '../../Styles/Containers'
import { H2, ParagraphText } from '../../Styles/Texts'
import { useTranslation } from 'react-i18next'

import OTSTCLogo from '../../Assets/Icons/img_by_creative_biometric.png'
import { OtstcLogo } from '../../Styles/Images'

const FooterView = (props: any) => {
    const { t } = useTranslation()

    // const sendMail = () => {
    //     let panierString: any = [[], [], []]

    //     for (let i = 0; i < panier.length; i++) {
    //         for (let j = 0; j < panier[i].length; j++) {
    //             panierString[i].push(panier[i][j]["sku"])
    //         }
    //     }

    //     let preoccupationTab: Array<any> = []

    //     if (questions[5][0] !== castAnalysesConcernForAnswers(analyses[0].name)) {
    //         preoccupationTab.push(setAnswerTranslation(questions[5][0], t))
    //         preoccupationTab.push(setConcernTranslation(t, analyses[0].name))
    //         preoccupationTab.push(setConcernTranslation(t, analyses[1].name))
    //     } else {
    //         preoccupationTab.push(setAnswerTranslation(questions[5][0], t))
    //         preoccupationTab.push(setConcernTranslation(t, analyses[1].name))
    //         preoccupationTab.push(setConcernTranslation(t, analyses[2].name))
    //     }

    //     if (preoccupationTab[0] === preoccupationTab[2]) {
    //         preoccupationTab[2] = setConcernTranslation(t, analyses[2].name)
    //     }

    //     console.log(panierString)
    //     console.log(preoccupationTab)

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Cookie", "PHPSESSID=6ufig6d02q2t08pp729n7d5jq9");

    //     let raw = JSON.stringify({
    //         "token": "73a6195f-d489-4a5f-8658-0a8ae61ce386",
    //         "user": adressMail,
    //         "carts": panierString,
    //         "subscribed": subscribed,
    //         "advises": preoccupationTab,
    //         "language": getLanguageforAPI()
    //     });

    //     let isQA: boolean = document.URL.includes("qa-")

    //     let url = isQA ? "https://myblend.wakemeup-dns.com/wp-json/myblend-api/v1/store_diag" : "https://my-blend.com/wp-json/myblend-api/v1/store_diag"
    //     if (window.market === "uk") {
    //         url = isQA ? "https://myblend-uk.wakemeup-dns.com/wp-json/myblend-api/v1/store_diag" : "https://my-blend.co.uk/wp-json/myblend-api/v1/store_diag"
    //     }

    //     fetch(url, {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     })
    //         .then(response => response.json())
    //         .then(() => {
    //             analyticsObject.sendAnalyticsEvent("share_diag", { "subscribe": subscribed === 1 })
    //         })
    //         .catch(error => alert(t('mail.error', 'Erreur lors de l’envoi du diagnostic')))

    // }

    // const onSubscribedChanged = () => {
    //     if (subscribed === 0) {
    //         setSubscribed(1)
    //     } else {
    //         setSubscribed(0)
    //     }
    // }


    // const getLanguageforAPI = () => {
    //     switch (i18n.language) {
    //         case 'en':
    //             return "EN"
    //         case 'fr':
    //             return "FR"
    //         case 'ru':
    //             return "RU"
    //         case 'es':
    //             return "ES"
    //         case 'de':
    //             return "DE"
    //         case 'pt':
    //             return "PT"
    //         case 'it':
    //             return "IT"
    //         default:
    //             return "FR"
    //     }
    // }

    return (
        <ComponentContainer style={{ alignItems: 'center' }}>
            {/* <img src={RitualKit} alt={'Ritual Kit'} style={{ marginBottom: '48px', width: '100%' }} />
            <H2>{t('ritual.title', "MY BEAUTY RITUAL")}</H2>
            <ParagraphText style={{ marginTop: '20px', marginLeft: '48px', marginRight: '48px' }}>{t('ritual.text', "Vous désirez tester votre prescription ? Personnalisez votre set découverte composé de la Crème Essential, vos 2 Superserums et 3 produits complémentaires au choix. Sa valeur sera déduite sur votre prochaine commande.")}</ParagraphText>
            <VolumeAndPrinceText>
                50,00{displayCurrencySymbol()}
            </VolumeAndPrinceText>
            <MainButton
                onClick={() => {
                    analyticsObject.sendAnalyticsEvent("ritual_kit_CTA_click", "")
                    dispatch({ type: ActionsTypes.SET_STEP, value: 5 });
                }}
                style={{ width: '224px', marginBottom: '50px' }}>{t('ritual.compose', "Composer mon set")}</MainButton> */}

            {/* <WhitePanel>
                <img src={MyBlendLogo} alt={'Ritual Set'} style={{ margin: '48px 0 40px 0', width: '60px', height: '60px' }} />
                <ParagraphText style={{ padding: '0 24px 0 24px', margin: '0 0 30px 0' }}>
                    {t('mail.title', 'Recevez votre diagnostic complet et votre prescription sur votre email')}
                </ParagraphText>
                <EmailInput onChange={e => setAdressMail(e.target.value)} placeholder={t('mail.textfield', 'Entrez votre adresse email')}></EmailInput>
                <label style={{ padding: '22px 0 22px 0' }}>
                    <Checkbox
                        name="my-checkbox"
                        onChange={() => onSubscribedChanged()}
                    />
                    <a style={{ font: 'normal normal 400 13px/20px GothamMedium' }}>{t('mail.newsletter', 'J’en profite pour m’inscrire à la newsletter')}</a>
                </label>
                <MainButton style={{ margin: '0 0 50px' }} onClick={() => sendMail()}>
                    {t('mail.send', 'Envoyer')}
                </MainButton>
            </WhitePanel> */}

            <H2 style={{ padding: '50px 0 20px 0' }}>{t('footer.title', "Une méthode unique")}</H2>
            <ParagraphText style={{ padding: '0px 32px 72px' }}>
                {t('footer.subtitle', "Le développement d’un algorithme unique, associé à une évaluation rigoureuse du bio-environnement cutané, fait de MySkinDiag, un outil de haute technologie, à la précision optimisée.")}
            </ParagraphText>

            <OtstcLogo style={{ bottom: "144px" }} src={OTSTCLogo} alt="By OTSTC" />
        </ComponentContainer>
    )
}

export default FooterView