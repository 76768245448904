import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import arrow from "../../Assets/arrow_back.svg"

const TOP = 120

const idle = keyframes`
  0% {
    bottom: ${TOP}px;
  }

  50% {
    bottom: ${TOP + 20}px;
  }

  100% {
    bottom: ${TOP}px;
  }
`

const ImageContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: ${TOP}px;
  left: 20px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  animation: ${idle} 0.8s linear infinite;
  pointer-events: none;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  transform: rotate(270deg);
`;

const AnimatedScrollArrow = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ImageContainer isVisible={isVisible}>
      <Image
        src={arrow} // Remplacez par le chemin de votre image
        alt="Image"
      />
      <Image
        style={{ top: "12px" }}
        src={arrow} // Remplacez par le chemin de votre image
        alt="Image"
      />
    </ImageContainer>
  );
};

export default AnimatedScrollArrow;
