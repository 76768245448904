import * as ActionTypes from '../Constants/ActionsTypes'
import { MyBlendState } from '../type'

const initialState: MyBlendState = {
    session: {
        step: 0,
        error: [],
        imageUser: "",
        analyses: [
            { name: "WRINKLES", score: 0.8552355714285714 },
            { name: "WRINKLES_UNDER_EYE", score: 0.7 },
            { name: "EYES_BAGS", score: 0.121688, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=YYQYEdOP3xaIY7xBogaXb3fKC4s%3D" },
            { name: "DARK_SPOTS", score: 0.544971, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=nHQrsAAJz896qB63TB2s7AT5WJQ%3D" },
            { name: "REDNESS", score: 1, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Ca5t3d%2BFlKSCQ2FMyX4Fl9RUCTQ%3D" },
            { name: "SHININESS", score: 0.12, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Xf6kxvvX1aTRyM0H%2B929CZVQpTQ%3D" },
            { name: "RADIANCE", score: 0.3, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Jt%2FeKLoL9EUaDUaREOIAJzfiw98%3D" },
            { name: "ACNE", score: 0.1, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…22796695&Signature=49TYD4Xp8k94WsJslO4aQQsehLg%3D" },
            { name: "PORES", score: 0.5, image: "https://s3.eu-west-1.amazonaws.com/otstc.photos/ab…796695&Signature=Cfu1WXlJ%2F0B9vCloj56c0mSvn4E%3D" },
            { name: "HOLLOW_CHEEKS", score: 0.6 },
            { name: "HYDRATATION", score: 0.7 },
        ],
        analyseStep: 0,
        questions: ["woman", "age50plus", "noProblem", ["serum", "cream", "mask", "eyes", "accessories", "makeupremover", "lotion", "cleanser"], "rinseWithWater", ["drySkin", "radiance", "acne"], "textureCream", ["city"], "stressed", "insufisant", ["coffee"]],
        cheek: "",
        positions: [],
        recommendedProducts: [[], [], []],
        actualConcern: "",
        actualConcernName: "",
        allRitualProducts: [[], [], []],
        ritualKitProducts: [],
        analyticsObject: {},
        firstLaunch: true,
        firstLaunchResult: true,
    }
}

const enabledConcerns = [
    "WRINKLES",
    "WRINKLES_UNDER_EYE",
    "EYES_BAGS",
    "DARK_CIRCLES",
    "REDNESS",
    "SHININESS",
    "RADIANCE",
    "ACNE",
    "PORES",
    "DARK_SPOTS",
    "TEAR_THROUGH",
    "HOLLOW_CHEEKS",
    "LOWER_FACE_PTOSIS",
    "HYDRATATION"
]

const reducer = (state = initialState, action: any): MyBlendState => {
    let nextstate
    let tabRitual: Array<any>


    switch (action.type) {
        case ActionTypes.SET_ANALYTIC_OBJECT:
            nextstate = {
                session: {
                    ...state.session,
                    analyticsObject: action.value
                }
            }
            return nextstate
        case ActionTypes.SET_ACTUAL_CONCERN:
            nextstate = {
                session: {
                    ...state.session,
                    actualConcern: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_ACTUAL_CONCERN_NAME:
            nextstate = {
                session: {
                    ...state.session,
                    actualConcernName: action.value
                }
            }
            return nextstate

        case ActionTypes.UPDATE_RITUAL_KIT:
            tabRitual = state.session.ritualKitProducts
            if (state.session.ritualKitProducts.some((e: any) => e.sku === action.value.sku)) {
                tabRitual = tabRitual.filter((e: any) => e.sku !== action.value.sku)
            }
            else if (tabRitual.length < 6) {
                tabRitual.push(action.value)
            }
            else if (action.value === -2) {
                tabRitual.pop()
            }
            else {
                tabRitual.push(-1)
            }
            nextstate = {
                session: {
                    ...state.session,
                    ritualKitProducts: tabRitual
                }
            }
            console.log(nextstate.session.ritualKitProducts)
            return nextstate

        case ActionTypes.RESET_EXPERIENCE:
            console.log("RESET_EXPERIENCE")
            nextstate = {
                session: {
                    ...state.session,
                    analyses: [],
                    cheek: '',
                    questions: [],
                    recommendedProducts: [],
                    imageUser: ''
                }
            }
            return nextstate

        case 'SET_POSITIONS':
            nextstate = {
                session: {
                    ...state.session,
                    positions: action.value
                }
            }
            return nextstate

        case ActionTypes.SET_STEP:
            nextstate = {
                session: {
                    ...state.session,
                    step: action.value
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_PHOTO:
            nextstate = {
                session: {
                    ...state.session,
                    step: 2,
                    imageUser: action.value
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_ERROR:
            nextstate = {
                session: {
                    ...state.session,
                    step: 1,
                    imageUser: null,
                    error: action.value
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_UPDATE:
            nextstate = {
                session: {
                    ...state.session,
                    analyseStep: action.value,
                    // step: 2
                }
            }
            return nextstate

        case ActionTypes.ANALYSE_BIOMETRICS_DONE:
            if (state.session.step === 2)
                nextstate = {
                    session: {
                        ...state.session,
                        biometrics: action.value,
                    }
                }
            return nextstate || state

        case ActionTypes.ANALYSE_DONE:
            let tab: Array<any> = []
            console.log(action.value)
            action.value.forEach((element: any) => {
                if (enabledConcerns.includes(element.name)) {
                    tab.push(element)
                }
            });
            let eyesBags = tab.find((e: any) => e.name === 'EYES_BAGS')
            let darkCircles = tab.find((e: any) => e.name === 'DARK_CIRCLES')
            let tearthrough = tab.find((e: any) => e.name === 'TEAR_THROUGH')
            let hollowCheeks = tab.find((e: any) => e.name === 'HOLLOW_CHEEKS')
            let lowerFace = tab.find((e: any) => e.name === 'LOWER_FACE_PTOSIS')

            if (eyesBags.score > darkCircles.score) {
                tab = tab.filter(function (e) {
                    return e.name !== 'DARK_CIRCLES'
                })
            }
            else {
                tab = tab.filter(function (e) {
                    return e.name !== 'EYES_BAGS'
                })
            }

            if (tearthrough.score > hollowCheeks.score && tearthrough.score > lowerFace.score) {
                tab = tab.filter(function (e) {
                    return e.name !== 'HOLLOW_CHEEKS'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'LOWER_FACE_PTOSIS'
                })
            }
            else if (hollowCheeks.score > tearthrough.score && hollowCheeks.score > lowerFace.score) {
                tab = tab.filter(function (e) {
                    return e.name !== 'TEAR_THROUGH'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'LOWER_FACE_PTOSIS'
                })
            }
            else {
                tab = tab.filter(function (e) {
                    return e.name !== 'TEAR_THROUGH'
                })
                tab = tab.filter(function (e) {
                    return e.name !== 'HOLLOW_CHEEKS'
                })
            }

            //Gestion des images et des scores
            //Pas d'image pour l'hydration

            let wrinklesArray: Array<string> = ["WRINKLES_NASOLABIAL_FOLDS", "WRINKLES_UPPER_LIPS", "WRINKLES_FOREHEAD_WRINKLES", "WRINKLES_FROWN_LINES", "WRINKLES_MARIONNETTE_LINES"]
            let wrinklesArray2: Array<any> = action.value.filter((e: any) => wrinklesArray.includes(e.name))
            let wrinklesMax = wrinklesArray2.reduce((a: any, b: any) => { return (a.score > b.score) ? a : b })

            tab.find((e: any) => e.name === "WRINKLES").image = wrinklesMax.image
            tab.find((e: any) => e.name === "WRINKLES").score = wrinklesMax.score

            let darkspots: Array<string> = ["DARK_SPOTS_INTENSITY", "DARK_SPOTS_NUMBER", "DARK_SPOTS_SURFACE"]
            let darkspotsArray: Array<any> = action.value.filter((e: any) => darkspots.includes(e.name))
            let darkspotsMax = darkspotsArray.reduce((a: any, b: any) => { return (a.score > b.score) ? a : b })

            tab.find((e: any) => e.name === "DARK_SPOTS").image = darkspotsMax.image
            tab.find((e: any) => e.name === "DARK_SPOTS").score = darkspotsMax.score

            let undereye: Array<string> = ["WRINKLES_UNDER_EYE", "WRINKLES_CROWS_FEET"]
            let undereyeArray: Array<any> = action.value.filter((e: any) => undereye.includes(e.name))
            let undereyeMax = undereyeArray.reduce((a: any, b: any) => { return (a.score > b.score) ? a : b })

            tab.find((e: any) => e.name === "WRINKLES_UNDER_EYE").image = undereyeMax.image
            tab.find((e: any) => e.name === "WRINKLES_UNDER_EYE").score = undereyeMax.score * 1.3178

            tab.find((e: any) => e.name === "SHININESS").score = tab.find((e: any) => e.name === "SHININESS").score - 0.08 * 5.4976
            if (tab.find((e: any) => e.name === "SHININESS").score < 0) {
                tab.find((e: any) => e.name === "SHININESS").score = 0
            }
            tab.find((e: any) => e.name === "REDNESS").score = Math.min(tab.find((e: any) => e.name === "REDNESS").score * 1.223, 100)
            tab.find((e: any) => e.name === "ACNE").score = Math.min(tab.find((e: any) => e.name === "ACNE").score * 1, 100)
            tab.find((e: any) => e.name === "PORES").score = tab.find((e: any) => e.name === "PORES").score
            tab.find((e: any) => e.name === "RADIANCE").score = Math.min((1 - tab.find((e: any) => e.name === "RADIANCE").score) * 1.2, 100)
            tab.find((e: any) => e.name === "HYDRATATION").score = tab.find((e: any) => e.name === "HYDRATATION").score + (Math.floor(Math.random() * 10) - 5) / 100
            if (tab.find((e: any) => e.name === "HYDRATATION").score > 0.8) {
                tab.find((e: any) => e.name === "HYDRATATION").score = tab.find((e: any) => e.name === "HYDRATATION").score - 0.2
            }

            nextstate = {
                session: {
                    ...state.session,
                    analyses: tab,
                }
            }
            return nextstate || state

        case ActionTypes.RECOMMENDATION_UPDATE:
            let currentProducts = state.session.products
            let newProducts = currentProducts.concat(action.value)

            nextstate = {
                session: {
                    ...state.session,
                    products: newProducts,
                }
            }
            return nextstate
        case ActionTypes.SET_QUESTION:
            nextstate = {
                session: {
                    ...state.session,
                    questions: action.value
                }
            }
            return nextstate

        case ActionTypes.FILL_RECOMMENDED_PRODUCTS:
            nextstate = {
                session: {
                    ...state.session,
                    recommendedProducts: action.value
                }
            }
            return nextstate

        case ActionTypes.FILL_RITUAL_KIT:
            nextstate = {
                session: {
                    ...state.session,
                    allRitualProducts: action.value
                }
            }
            return nextstate
            
        case ActionTypes.UPDATE_MODEL:
            nextstate = {
                session: {
                    ...state.session,
                    model: action.value,
                },
            };
            return nextstate;

        default:
            return state
    }
}

export default reducer
