import { useEffect, useState } from "react"
import { H1, Retour } from '../Styles/Texts'
import { Quest, setQuest, setAnswers, setAnswerTranslation, setIcon, setSelectionnable, Answer, setOrder } from '../Constants/QuestsConstants'
import { QuestionNavBar, QuestsViewContainer, QuestContainer, ProgressBarEmpty, ProgressBar, IconContainer, NextQuestContainer, AnswerContainer } from "../Styles/Containers"
import { QuestButton, MainButton, OrderIcon } from "../Styles/Buttons"
import { BackQuestIcon, LogoSVG, QuestIcon } from '../Styles/Images'
import { useDispatch, useSelector } from "react-redux"
import { MyBlendState } from "../type"
import * as ActionTypes from '../Constants/ActionsTypes'
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { CSSTransition } from 'react-transition-group'
import { AnalyticsObject } from "../Analytics/analytics"
import { openMBWebsite } from "../Constants/Functions"
import i18n from "../i18nextInit"
const NextQuestButton = styled(MainButton)`
margin-top: 0;
`;

const QuestsView = () => {
    const [questStep, setQuestStep] = useState(Quest.gender)
    const [answerButtons, setAnswerButtons] = useState<any[]>([])
    const [selected, setSelected] = useState<any>([])
    const [answer, setAnswer] = useState<any>([])
    const [inProp] = useState<boolean>(false)
    const [answerInProp, setAnswerInProp] = useState<boolean>(false)
    const step = useSelector((state: MyBlendState) => state.session.step)
    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)
    const analyses = useSelector((state: MyBlendState) => state.session.analyses)
    const dispatch = useDispatch()
    const session = useSelector((state: MyBlendState) => state.session)
    const { t } = useTranslation()

    const LONG_QUESTS: number[] = window.innerWidth < 769 ? [3, 5, 7, 10] : []

    const nextStep = (ans: any, e: any) => {

        if (setSelectionnable(questStep)) {
            if (selected.includes(ans)) {
                setSelected(selected.filter((item: Answer) => item !== ans))
                e.target.blur() //desactive le hover
            } else {
                if (ans === Answer.none || ans === Answer.noAnswer) {
                    setSelected([])
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                } else {
                    if (selected.includes(Answer.noAnswer) || selected.includes(Answer.none)) {
                        setSelected([])
                    }
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                }
            }
        } else if (setOrder(questStep)) {
            if (selected.includes(ans)) {
                setSelected(selected.filter((item: Answer) => item !== ans))
                e.target.blur() //desactive le hover
            } else {
                if (selected.length > 2) {
                    let array: Array<number> = selected
                    array.pop()
                    //setSelected(array)
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                } else {
                    setSelected((arr: Array<Answer>) => [...arr, ans])
                }
            }
        }
        else {
            setAnswer((arr: Array<Answer>) => [...arr, ans])
            setSelected((arr: Array<Answer>) => [...arr, ans])

            setTimeout(() => {
                e.target.blur() //desactive le hover

                let quest: number = questStep
                quest++
                setAnswerInProp(false)
                //setInProp(false)
                setQuestStep(quest)
            }, 200);
        }
    }

    const nextStepWithMultipleChoices = (e: any) => {
        setTimeout(() => {
            setAnswerInProp(false)
            //setInProp(false)
            e.target.blur() //desactive le hover
            setSelected([])
            let quest: number = questStep
            quest++
            setAnswer((arr: Array<Answer>) => [...arr, selected])
            setQuestStep(quest)
        }, 200);
    }

    useEffect(() => {
        if (questStep === 11) {
            analyticsObject.sendAnalyticsEventForQuest(answer)
            analyticsObject.sendAnalyticsEventForScores(analyses)
            analyticsObject.sendAnalyticsEvent("analysis_done", "")

            dispatch({ type: ActionTypes.SET_STEP, value: 2 })
            dispatch({ type: ActionTypes.SET_QUESTION, value: answer })
        }

        let tab = setAnswers(questStep)
        let elementTab: any = []
        let key: number = 0
        tab.forEach((e) => {
            elementTab.push(
                <QuestButtonComponent e={e} key={key} islong={LONG_QUESTS.includes(questStep)} />
            )
            key++
        })
        setAnswerButtons(elementTab)
        //eslint-disable-next-line
    }, [questStep, selected])


    // vide les questions selectionnees a chaque question
    useEffect(() => {
        window.scrollTo(0, 0)

        if (questStep === Quest.cleanser_Water && !answer.flat().includes(Answer.cleanser)) {
            answer.push("tweak")
            let quest: number = questStep
            quest++
            setQuestStep(quest)
        }
        setSelected([])
        //setInProp(true)
        setAnswerInProp(true)
        //eslint-disable-next-line
    }, [questStep])

    const QuestButtonComponent = (props: any) => {

        return (
            <QuestButton onClick={(event) => nextStep(props.e, event)} $isLong={LONG_QUESTS.includes(questStep)} isSelected={selected.includes(props.e)}>
                {setIcon(props.e) && <IconContainer>
                    <div style={{ display: "flex", width: "32px", justifyContent: 'center', height: "32px" }}>
                        <QuestIcon isSelected={!selected.includes(props.e)} url={setIcon(props.e)} />
                    </div>
                </IconContainer>}
                {setAnswerTranslation(props.e, t)}
                {setOrder(questStep) && selected.includes(props.e) && <OrderIcon $isLong={LONG_QUESTS.includes(questStep)} id={'ORDER'}>{selected.indexOf(props.e) + 1}</OrderIcon>}
            </QuestButton>
        )
    }

    const backStep = () => {
        setSelected([])
        if (questStep === 0) {
            dispatch({ type: ActionTypes.SET_STEP, value: step - 2 })
        } else {
            let quest: number = questStep
            if(answer.includes("tweak") && questStep === 5){
                quest--
            }
            quest--
            setQuestStep(quest)
            let answerTab = answer
            answerTab.pop()
            setAnswer(answerTab)
        }
    }

    useEffect(() => {
        analyticsObject.sendAnalyticsEvent("camera_done", "")
        session.firstLaunch = true;
        //eslint-disable-next-line
    }, [])

    return (
        <CSSTransition in={inProp} classNames="swipeToRight" addEndListener={() => null}>
            <QuestsViewContainer style={{ alignItems: 'stretch', overflowX: 'hidden', display: 'block' }}>
                <QuestionNavBar>
                    <LogoSVG onClick={() => openMBWebsite(i18n.language)}/>
                </QuestionNavBar>
                <Retour onClick={() => backStep()} > {t('menu.back', 'Retour')} </Retour>
                <BackQuestIcon step={questStep} onClick={() => backStep()} />
                <ProgressBarEmpty />
                <ProgressBar step={questStep < 12 ? questStep : 12} />
                <CSSTransition in={answerInProp} classNames="swipeToRight" addEndListener={() => null}>
                    <QuestContainer style={{ position: 'relative', padding: '0 0 32px 0' }} className="scrollbar-hidden">
                        <H1 style={{ padding: '72px 24px 28px 24px' }}>{setQuest(questStep, t)}</H1>
                        <AnswerContainer $isLong={LONG_QUESTS.includes(questStep)}>
                            {answerButtons}
                        </AnswerContainer>
                    </QuestContainer>
                </CSSTransition>
                <NextQuestContainer questStep={(setSelectionnable(questStep) && selected.length > 0) || (setOrder(questStep) && selected.length === 3)}>
                    <NextQuestButton onClick={(e) => nextStepWithMultipleChoices(e)}>{t('menu.next', "Suivant")}</NextQuestButton>
                </NextQuestContainer>
            </QuestsViewContainer>
        </CSSTransition>

    )
}

const QuestsViewComponent = () => {
    return <QuestsView />
}

export default QuestsViewComponent