import * as ActionType from '../../Constants/ActionsTypes'
// import getProductRecommendation, { Product } from '../Firebase/getProductRecommendation'
//import * as faceapi from 'face-api.js'

import {
    SkinDiagApiConfig,
    defaultCBSKConfig,
    Biometrics,
    CBSKEndPoint,
    CBSKError,
    AnalyseStep,
    Point,
} from './defaults'

import { CBSKWarning, riseWarnings } from './warnings'
import { Analyse, parseAnalyse } from './analysis'

export class SkinDiagApi {
    config: SkinDiagApiConfig = defaultCBSKConfig()

    async getWarning(blob: Blob) {
        console.log("==> [SkinDiag] Get Warnings")

        var formData = new FormData();
        formData.append("face", blob, "sample.jpg");
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.warnings, parameters)
        console.log("==> [SkinDiag] Get Warnings : Response " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Warnings: Fail ")
            return [CBSKError.no_face]
        }
        let json = await response.json()
        let warnings: Array<CBSKWarning> = json['cbsk']['warnings'] as Array<CBSKWarning>
        console.log("==> [SkinDiag] Get Warnings : Warnings ")
        console.log(warnings)
        return riseWarnings(warnings, this.config.warnings)
    }
    async getBiometrics(blob: Blob) {
        console.log("==> [SkinDiag] Get Biometrie")

        var formData = new FormData();
        formData.append("face", blob, "sample.jpg");
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.biometrics, parameters)
        console.log("==> [SkinDiag] Get Biometrie: Response " + response.status)
        let json = await response.json()

        return json['cbsk']['biometrics'] as Biometrics
    }

    async getAnalysis(face: Blob) {
        console.log("==> [SkinDiag] Get Analysis")
        var formData = new FormData();
        formData.append("face", face, "sample.jpg");
        //formData.append("cheek", cheek, "cheek.jpg");
        formData.append("saveImages", 'false');
        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.analyze, parameters)
        console.log("==> [SkinDiag] Get Analysis:  " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Analysis: Fail ")
            return [CBSKError.no_analyse]
        }
        let json = await response.json()
        console.log(json)
        return parseAnalyse(json)
    }
    // async analyse(uri:any){
    //     console.log("==> [SkinDiag] Analysing Image")
    //     let face = await fetch(uri).then(r => r.blob());

    //     let errorWarnings = await this.getWarning(face)
    //     console.log(errorWarnings)
    //     if(errorWarnings.length !== 0 ){ // Error in warnings
    //         throw errorWarnings      
    //     }

    //     // Biometrie is useless
    //     // let biometrie = await this.getBiometrics(face)
    //     // if(!biometrie){ // Error in biometrie
    //     //     throw [CBSKError.no_biometrics] 
    //     // }
    //     return await this.getAnalysis(face,face)
    // }

    dataURItoBlob(dataURI: any) {
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }


    /**
* recupere les points de l'analyse
* @param {int} index 
* @param {int} zone 
*/
    getPoint(index: number, zone: number, biometris: Biometrics): Point {
        var point: Point = { x: 0, y: 0 };
        console.log(biometris)
        if (!zone) {
            point.x = biometris.contour[index][0];
            point.y = biometris.contour[index][1];
        } else {
            point.x = biometris.zones[zone][index][0];
            point.y = biometris.zones[zone][index][1];
        }
        return point;
    }

    // getLocalPoint():Point {

    // }

    // async cropCheek(face: any) { //,
    //     console.log("==> [SkinDiag] Cropping Cheek")
    //     var cheek = new Image()
    //     var canvas = document.createElement('canvas')
    //     var ctx = canvas.getContext('2d')

    //     const imageFace = await faceapi.bufferToImage(face)
    //     const detection = await faceapi.detectSingleFace(imageFace!,
    //         new faceapi.TinyFaceDetectorOptions({
    //             scoreThreshold: 0.30
    //         })).withFaceLandmarks()


    //     return new Promise(function (resolve, reject) {
    //         cheek.onload = function () {
    //             let a: Point = detection!.landmarks.getJawOutline()[0]
    //             let b: Point = detection!.landmarks.getNose()[4]

    //             let origin: Point = new faceapi.Point(a.x + 0.33 * (b.x - a.x), a.y + 0.33 * (b.y - a.y))

    //             let distanceNezMachoire: number = Math.sqrt( //distance machoire-nez
    //                 Math.pow(a.x - b.x, 2) +
    //                 Math.pow(a.y - b.y, 2)
    //             )
    //             let width = distanceNezMachoire / 3.3

    //             let increaseFactor = 5 / 100
    //             let increaseFactorX = width * increaseFactor
    //             let increaseFactorY = width * increaseFactor
    //             let cropWidth = width + increaseFactorX
    //             let cropHeight = width + increaseFactorY

    //             canvas.height = width
    //             canvas.width = width

    //             ctx?.drawImage(cheek,
    //                 origin.x - increaseFactorX / 2, origin.y - increaseFactorY / 2,   // Start at 70/20 pixels from the left and the top of the image (crop),
    //                 cropWidth, cropHeight,   // "Get" a `50 * 50` (w * h) area from the source image (crop),
    //                 0, 0,     // Place the result at 0, 0 in the canvas,
    //                 width, width // With as width / height: 100 * 100 (scale)
    //             )

    //             canvas.toBlob(function (blob) {
    //                 console.log("==> [SkinDiag] Cropping Cheek: FIN")
    //                 resolve(blob)

    //                 return blob
    //             })
    //         }
    //         cheek.src = URL.createObjectURL(face); // Load
    //     })

    // }

    async getEffect(faceURI: any, effect: string) {
        let face = await fetch(faceURI).then(r => r.blob());

        console.log("==> [SkinDiag] Get Effect: " + effect)
        var formData = new FormData();
        formData.append("face", face, "sample.jpg");
        formData.append("effect", effect);

        const parameters = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': "bearer " + this.config.apiKey,
            },
        }
        let response = await fetch(this.config.url + CBSKEndPoint.effect, parameters)
        console.log("==> [SkinDiag] Get Effect:  " + response.status)
        if (response.status !== 200) {
            console.log("==> [SkinDiag] Get Effect: Fail ")
            return [CBSKError.no_analyse]
        }
        let json = await response.json()

        return json["url"]

    }
}

//middleware
export default async function skinDiagDispatcher(dispatch: any, getState: any) {
    console.log("==> [SkinDiag] Start Grand Skin Diag Dispatcher")
    let state = getState()
    // function appendProducts(products:Array<Product>){
    //     products.forEach(element => {
    //         console.log("Add : " + element.name)
    //     });
    //     dispatch( { type: ActionType.RECOMMENDATION_UPDATE, value: products})
    // }
    dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.start })
    if (state && state.session && state.session.imageUser) {
        // Test Effect - DO NOT DELETE
        let skinDiag = new SkinDiagApi()
        // skinDiag.getEffect(state.session.imageUser,"17_443_5DDMJ_ANTI_FATIGUE_ELIXIR_V4 (1)New").then( url => {
        //     console.log("URL Effect:" + url)
        //     dispatch({type: ActionType.SET_EFFECT, value: url})
        // })

        let face = await fetch(state.session.imageUser).then(r => r.blob());

        skinDiag.getWarning(face).then(warnings => {
            if (warnings.length !== 0) { // Error in warnings
                dispatch({ type: ActionType.ANALYSE_ERROR, value: warnings })
                throw new Error("==> [SkinDiag] Warning Fail")
            }
            dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.analysingFace })

            skinDiag.getBiometrics(face).then(biometrics => {
                setTimeout(() => {
                    dispatch({ type: ActionType.SET_STEP, value: 3 })
                }, 3000);
                dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.biometricsDone })
                dispatch({ type: ActionType.ANALYSE_BIOMETRICS_DONE, value: biometrics })
                skinDiag.getAnalysis(face).then(items => {
                    console.log("==> [SkinDiag] Analyse Done")
                    let analysis = items as Array<Analyse>
                    if (analysis) {
                        dispatch({ type: ActionType.ANALYSE_UPDATE, value: AnalyseStep.done })
                        dispatch({ type: ActionType.ANALYSE_DONE, value: analysis })
                    } else {
                        dispatch({ type: ActionType.ANALYSE_ERROR, value: CBSKError.no_analyse })
                        throw new Error("==> [SkinDiag] Analyse Fail")
                    }
                })

            })
        })
            .catch(error => {
                console.error(error)
            })
    } else {
        console.log("==> [SkinDiag] No Photo ==>")
        const action = { type: ActionType.ANALYSE_ERROR, value: [CBSKError.no_photo] }
        dispatch(action)
    }
}
