import styled from 'styled-components'
import logo from '../Assets/LOGO.svg'
import arrowBack from '../Assets/arrow_back.svg'
import cameraIcon from '../Assets/Icons/ICON_camera.svg'
import bubble from '../Assets/Icons/ICONQuestionnaire.svg'
import lock from '../Assets/Icons/ICONSecurite.svg'
import oval from '../Assets/oval.svg'
import close from '../Assets/Icons/ICONClose.svg'
import ProductThumbnail from '../Assets/bloc-visuel-pdt-opt1.png'
import eyesIcon from '../Assets/Icons/ICONYeux.svg'
import distanceIcon from '../Assets/Icons/ICONDistance.svg'
import positionIcon from '../Assets/Icons/ICONPosition.svg'
import orientationIcon from '../Assets/Icons/ICONOrientation.svg'
import arrowCloseIcon from '../Assets/Icons/ICONArrow-closer.svg'
import arrowFarIcon from '../Assets/Icons/ICONArrow-further.svg'
import arrowRightIcon from '../Assets/Icons/ICONArrow-right.svg'
import arrowLeftIcon from '../Assets/Icons/ICONArrow-left.svg'
import arrowUpIcon from '../Assets/Icons/ICONArrow-higher.svg'
import arrowDownIcon from '../Assets/Icons/ICONArrow-lower.svg'
import homeIcon from '../Assets/Icons/ICONHome.svg'
import cartIcon from '../Assets/Icons/ICONPanier.svg'
import sunLogo from "../Assets/Icons/ICONSoleil.svg"
 
export const IMG = styled.div`
    border-image: none;
    background-size: contain;
    background-position-y: center;
    background-repeat: no-repeat; 
`;

export const LogoSVG = styled(IMG)`
    width: 100px;
    height: 20px;
    background-image : url(${logo});
`;

export const BackIcon = styled(IMG) <{ step: number }>`
    display: ${props => (props.step === 3 || props.step === 2) && 'none'};
    position: fixed;
    left: 24px;
    width: 20px;
    height: 22px;
    background-image : url(${arrowBack});
`;

export const HomeIcon = styled(IMG)`
    position: fixed;
    left: 24px;
    width: 25px;
    height: 25px;
    background-image : url(${homeIcon});
`

export const BackQuestIcon = styled(IMG) <{ step: number }>`
    position: fixed;
    top: 20px;
    left: 24px;
    width: 20px;
    height: 22px;
    background-image : url(${arrowBack});
    z-index: 4;
`;

export const BackKitIcon = styled(IMG)`
    position: fixed;
    top: 20px;
    left: 24px;
    width: 20px;
    height: 22px;
    background-image : url(${arrowBack});
    z-index: 4;
`;

export const BackHomeIcon = styled(IMG)`
    position: relative;
    top: 8px;
    left: 24px;
    width: 20px;
    height: 16px;
    background-image : url(${arrowBack});
    z-index: 4;
`;

export const CameraIcon = styled(IMG)`
    width: 50px;
    height: 38px;
    background-image : url(${cameraIcon});
    margin-top: 30px;
`;

export const BubbleIcon = styled(IMG)`
    width: 50px;
    height: 40px;
    background-image : url(${bubble});
    margin-top: 30px;
`;

export const LockIcon = styled(IMG)`
    width: 40px;
    height: 40px;
    margin-top: 20px;
    position: relative;
    background-image : url(${lock});
`;

export const QuestIcon = styled(IMG) <{ url: any, isSelected: any }>`
    width: 100%;
    height: 100%;
    filter: ${props => props.isSelected ? 'invert(0)' : 'invert(1)'}; 
    background-image: url(${props => props.url})
`;

export const ConcernImg = styled.img`
    position: relative;
    width: 100%;
    height: 90%;
    mask-image: url(${oval});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    object-fit: cover;
`

export const NextArrow = styled(IMG) <{ isPointingRight: boolean, isDisplayed: boolean }>`
    position: absolute;
    // display: ${props => !props.isDisplayed && 'none'};
    top: 80px;
    width: 40px;
    height: 40px;
    left: ${props => !props.isPointingRight && '0px'};
    right: ${props => props.isPointingRight && '0px'};
    background-image : url(${arrowBack});
    transform: ${props => props.isPointingRight && 'rotate(180deg)'};

    @media (min-width: 768px) {
        top: 180px;
        margin: 24px 0px;
    }
`

export const ProductCellImage = styled(IMG) <{ url: string }>`
    width: 100px;
    height: 100px;
    background-image : ${props => props.url ? props.url : `url(${ProductThumbnail})`};
`

export const CloseIcon = styled(IMG)`
    position: absolute;
    height: 14px;
    width: 14px;
    top: 20px;
    right: 25px;
    background-image: url(${close});
`

export const EyesIcon = styled(IMG)`
    position: relative;
    height: 32px;
    width: 32px;
    background-image: url(${eyesIcon});
    filter: invert(30%) sepia(100%) saturate(6611%) hue-rotate(355deg) brightness(83%) contrast(97%);
`

export const PositionIcon = styled(IMG) <{ $isGood: boolean }>`
    position: relative;
    height: 32px;
    width: 32px;
    background-image: url(${positionIcon});
    filter: ${props => props.$isGood ? "invert(56%) sepia(88%) saturate(337%) hue-rotate(84deg) brightness(97%) contrast(94%)" : "invert(30%) sepia(100%) saturate(6611%) hue-rotate(355deg) brightness(83%) contrast(97%)"};
`

export const OrientationIcon = styled(IMG) <{ $isGood: boolean }>`
    position: relative;
    height: 32px;
    width: 32px;
    background-image: url(${orientationIcon});
    filter: ${props => props.$isGood ? "invert(56%) sepia(88%) saturate(337%) hue-rotate(84deg) brightness(97%) contrast(94%)" : "invert(30%) sepia(100%) saturate(6611%) hue-rotate(355deg) brightness(83%) contrast(97%)"};
`

export const LightWarnIcon = styled(IMG) <{ $isGood: boolean }>`
    position: relative;
    height: 32px;
    width: 32px;
    background-image: url(${sunLogo});
    filter: ${props => props.$isGood ? "invert(56%) sepia(88%) saturate(337%) hue-rotate(84deg) brightness(97%) contrast(94%)" : "invert(30%) sepia(100%) saturate(6611%) hue-rotate(355deg) brightness(83%) contrast(97%)"};
`

export const DistanceIcon = styled(IMG) <{ $isGood: boolean }>`
    position: relative;
    height: 32px;
    width: 32px;
    background-image: url(${distanceIcon});
    filter: ${props => props.$isGood ? "invert(56%) sepia(88%) saturate(337%) hue-rotate(84deg) brightness(97%) contrast(94%)" : "invert(30%) sepia(100%) saturate(6611%) hue-rotate(355deg) brightness(83%) contrast(97%)"};
`

export const RightArrowIcon = styled(IMG)`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: url(${arrowRightIcon});
    z-index: 10;

`

const handlePositionIcon = (isLeft: boolean, isTop: boolean, isRight: boolean, isBottom: boolean) => {
    if (isLeft) return `url(${arrowRightIcon})`
    else if (isTop) return `url(${arrowUpIcon})`
    else if (isRight) return `url(${arrowLeftIcon})`
    else if (isBottom) return `url(${arrowDownIcon})`
    return `url(${arrowUpIcon})`
}

export const PositionArrowIcon = styled(IMG) <{ $isLeft: boolean, $isTop: boolean, $isRight: boolean, $isBottom: boolean }>`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: ${props => handlePositionIcon(props.$isLeft, props.$isTop, props.$isRight, props.$isBottom)};
    z-index: 10;
`

export const UpArrowIcon = styled(IMG)`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: url(${arrowUpIcon});
    z-index: 10;

`

export const DownArrowIcon = styled(IMG)`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: url(${arrowDownIcon});
    z-index: 10;
`

export const DistanceArrowIcon = styled(IMG) <{ $isFar: boolean }>`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: ${props => props.$isFar ? `url(${arrowCloseIcon})` : `url(${arrowFarIcon})`};
    z-index: 10;
`

export const CloseArrowIcon = styled(IMG)`
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: url(${arrowCloseIcon});
    z-index: 10;
`

export const CartIcon = styled(IMG)`
    position: absolute;
    height: 25px;
    width: 25px;
    right: 24px;
    background-image: url(${cartIcon});
    display: flex;
    justify-content: center;
    align-items: 'center';
    color: black;
    padding-top: 15px;
    font-size: 12px;
`

export const OtstcLogo = styled.img`
position: absolute;
bottom: 84px;
right: -64px;
height: 12px;
object-fit: cover;
transform: rotate(-90deg);
`