import { useSelector } from "react-redux";
import {MyBlendState} from '../type';
// import CameraComponent from "./CameraView";
import NewCameraComponent from "./NewCamera"
import OnBoardingComponent from "./OnBoardingView";
import WaitingViewComponent from './WaitingView';
import QuestsViewComponent from './QuestsView';
import SignInComponent from './SignIn'
import MainResultViewComponent from './ResultView/Main'
import RitualKitViewComponent from './ResultView/RitualKitView'
const ViewDispatcher = () => {
    const step = useSelector((state: MyBlendState) => state.session.step)
    
    switch (step) {
        case -1:
            return(SignInComponent())
        case 0:
            return(OnBoardingComponent())
        case 1:
            return(NewCameraComponent())
        case 2:
            return(WaitingViewComponent())
        case 3: 
            return(QuestsViewComponent())
        case 4:
            return(MainResultViewComponent())
        case 5:
            return(RitualKitViewComponent())
        default:
            return(OnBoardingComponent())
    }
}

export default ViewDispatcher