import { ResultMainViewContainer, Bubble, BubbleContent, CarouselContentContainer, ProductDetailPopUp, GradiantImageLeft, GradiantImageRight } from '../../Styles/Containers'
import { H0, H3, ConcernText, H4, ParagraphText, Point } from '../../Styles/Texts'
import { ConcernImg, NextArrow } from '../../Styles/Images'
import Chart from 'react-apexcharts'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MyBlendState } from "../../type"
import { useSelector, useDispatch } from 'react-redux'
import { TFunction } from 'i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Reco from '../../Recos/GetRecoProducts'
import { MainButton } from '../../Styles/Buttons'
import * as ActionTypes from '../../Constants/ActionsTypes'
import { CSSTransition } from 'react-transition-group'
import { CloseIcon } from '../../Styles/Images'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactStringReplace from 'react-string-replace'
import AnimatedScrollArrow from './AnimatedScrollArrow'

export const setConcernTranslation = (t: TFunction, concern: string) => {
    switch (concern) {
        case 'WRINKLES':
            return (t('results.concern_wrinkles', 'Rides'))
        case 'WRINKLES_UNDER_EYE':
            return (t('results.concern_eyewrinkles', 'Rides des yeux'))
        case 'VOLUME_LOSS':
            return (t('results.concern_volumeloss', 'Perte de volume'))
        case 'EYES_BAGS':
            return (t('results.concern_eyebags', 'Poches et cernes'))
        case 'DARK_CIRCLES':
            return (t('results.concern_eyebags', 'Poches et cernes'))
        case 'REDNESS':
            return (t('results.concern_redness', 'Rougeurs'))
        case 'SHININESS':
            return (t('results.concern_shininess', 'Brillance'))
        case 'HETEROGENEITY':
            return (t('results.concern_heterogeneity', 'Heterogeneité'))
        case 'RADIANCE':
            return (t('results.concern_radiance', 'Manque d\'éclat'))
        case 'ROUGHNESS':
            return (t('results.concern_roughness', 'Roughness'))
        case 'ACNE':
            return (t('results.concern_acne', 'Acné'))
        case 'PORES':
            return (t('results.concern_pores', 'Pores'))
        case 'DARK_SPOTS':
            return (t('results.concern_darkspots', 'Tâches: pigmentation'))
        case 'LOWER_FACE_PTOSIS':
            return (t('results.concern_firmness', 'Manque de fermeté'))
        case 'HOLLOW_CHEEKS':
            return (t('results.concern_firmness', 'Manque de fermeté'))
        case 'TEAR_THROUGH':
            return (t('results.concern_firmness', 'Manque de fermeté'))
        case 'HYDRATATION':
            return (t('results.concern_hydration', 'Manque d\'hydratation'))
        case 'BLACK_SPOTS':
            return (t('results.concern_blackspots', 'Points noirs'))
        default:
            return "problem"
    }
}

export const setConcernForPreoccupation = (concern: string): string => {
    switch (concern) {
        case "LOWER_FACE_PTOSIS":
        case "TEAR_THOUGH":
        case "HOLLOW_CHEEKS":
            return "firmness"
        case "WRINKLES_UNDER_EYE":
        case "WRINKLES":
            return "wrinkles"
        case "HYDRATATION":
            return "moisturising"
        case "ACNE":
        case "SHININESS":
        case "BLACK_SPOTS":
        case "PORES":
            return "imperfections"
        case "REDNESS":
            return "redness"
        case "DARK_CIRCLES":
        case "EYES_BAGS":
            return "eyebags"
        case "DARK_SPOTS":
            return "darkspots"
        case "RADIANCE":
            return "radiance"
        default:
            return "moisturising"
    }
}
export const setConcernNameForPreoccupation = (concern: string): string => {
    switch (concern) {
        case "LOWER_FACE_PTOSIS":
            return "volumeloss"
        case "TEAR_THROUGH":
            return "firmness"
        case "HOLLOW_CHEEKS":
            return "firmness"
        case "WRINKLES_UNDER_EYE":
            return "eyewrinkles"
        case "WRINKLES":
            return "wrinkles"
        case "HYDRATATION":
            return "hydration"
        case "ACNE":
            return "acne"
        case "SHININESS":
            return "shininess"
        case "BLACK_SPOTS":
        case "PORES":
            return "pores"
        case "REDNESS":
            return "redness"
        case "DARK_CIRCLES":
            return "eyebags"
        case "EYES_BAGS":
            return "eyebags"
        case "DARK_SPOTS":
            return "darkspots"
        case "RADIANCE":
            return "radiance"
        default:
            return "moisturising"
    }
}

export const setConcernColor = (score: number) => {
    if (score < 0.4)
        return 'rgb(61,197,99)'
    else if (score < 0.8)
        return 'rgb(232,109,43)'
    else
        return 'rgb(209,24,15)'
}

export const PreoccupationText = styled(H3)`
    color: #1D1D1B;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 50px;
`

const PreoccupationAndSolutionContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0px;
`

const ConcernView = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const analyses = useSelector((state: MyBlendState) => state.session.analyses.sort((a: any, b: any) => {
        return b.score - a.score
    }))
    const questions = useSelector((state: MyBlendState) => state.session.questions)
    const [inPropSolutions, setInPropSolutions] = useState<boolean>(false)
    const [popUpSolutions, setPopUpSolutions] = useState<Array<any>>([])
    const [popUpPreoccupation, setPopUpPreoccupation] = useState<Array<any>>([])
    const [concernBubbles, setConcernBubbles] = useState<any>([])

    let isTablet = window.innerWidth > 768

    const setData = () => {
        let data: Array<any> = []
        analyses.forEach((e: any) => {
            data.push(Math.min(e.score * 80, 80) + 20)
        })
        for (let i = 0; i < 3; i++) {
            data.unshift(data[data.length - 1])
            data.pop()
        }
        return data
    }

    const setMarkersColors = () => {

        let data: Array<Object> = []
        let count: number = 0
        let analysesInOrder: Array<any> = []
        analysesInOrder = [...analyses]
        for (let i = 0; i < 3; i++) {
            analysesInOrder.unshift(analysesInOrder[analysesInOrder.length - 1])
            analysesInOrder.pop()
        }
        analysesInOrder.forEach((e: any) => {
            data.push(
                {
                    'dataPointIndex': count,
                    'seriesIndex': 0,
                    'fillColor': setConcernColor(e.score),
                    'size': 3,
                    'strokeColor': setConcernColor(e.score)
                })
            count++
        })


        return data
    }


    const [chartState] = useState<any>({
        options: {
            fill: {
                opacity: 0.2,
                colors: ['white']
            },
            chart: {
                type: 'radar',
                toolbar: {
                    show: false
                }
            },
            markers: {
                discrete: setMarkersColors()
            },
            yaxis: {
                show: false
            },
            xaxis: {
                show: false,
                categories: ['', '', '', '', '', '', '', '', '', '', '', '', '']
            },
            plotOptions: {
                size: 1,
                radar: {
                    polygons: {
                        strokeColor: 'green',
                        strokeWidth: 0,
                        fill: {
                            colors: ['rgba(0,0,0,0)']
                        },
                        width: 0,
                    }
                }
            },
            stroke: {
                show: false
            }
        },
        series: [
            {
                name: "Radar Series 1",
                data: setData()
            }],
    })

    useEffect(() => {
        //let actualRadius: number = Math.PI / 2 - Math.PI / 23
        let initialX = isTablet ? -30 : 130
        let initialY = isTablet ? -10 : 10
        let factorPI = 0
        let actualX = initialX
        let actualY = initialY
        let rayon = isTablet ? 240 : 130

        let tab: Array<any> = []
        analyses.forEach((e: any) => {
            actualX = initialX + rayon * Math.cos(factorPI * 2 * Math.PI / 11)
            actualY = initialY - rayon * Math.sin(factorPI * 2 * Math.PI / 11)

            tab.push(<ConcernBubble key={e.name} previousX={actualX} previousY={actualY} score={e.score} name={e.name} isInCarousel={false} />)

            factorPI++

        });
        setConcernBubbles(tab)

        let reco = new Reco()
        reco.getReco(analyses, questions, dispatch)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const displaySolutions = () => {
        if (popUpSolutions.length === 0) {
            let tab: Array<any> = []
            tab.push(<PopUpSolutions id={"SOLUTION"} key={0} setInPropSolutions={setInPropSolutions} t={t} setPopUpSolutions={setPopUpSolutions} />)
            setPopUpSolutions(tab)
            setInPropSolutions(true)
        }
    }

    const displayPreoccupations = () => {
        if (popUpPreoccupation.length === 0) {
            let tab: Array<any> = []
            tab.push(<PopUpPreoccupation key={0} setInPropSolutions={setInPropSolutions} t={t} displaySolutions={displaySolutions} setPopUpSolutions={setPopUpSolutions} setPopUpPreoccupation={setPopUpPreoccupation} />)
            setPopUpPreoccupation(tab)
            setInPropSolutions(true)
        }
    }

    const display = () => {
        if (popUpSolutions[0]) {
            return popUpSolutions[0]
        }
        else if (popUpPreoccupation[0]) {
            return popUpPreoccupation[0]
        }
        else {
            return (<div style={{ position: 'absolute' }}></div>)
        }
    }

    return (
        <ResultMainViewContainer id={"CONCERN_VIEW"}>
            <AnimatedScrollArrow />
            <H0 paddingTop={30}>{t('results.title', 'Résultats')}</H0>
            <PreoccupationText>{t('results.subtitle', 'Vos niveaux de préoccupations')}</PreoccupationText>
            <Chart options={chartState.options} series={chartState.series} type="radar" width={isTablet ? 500 : 300} height={isTablet ? 500 : 320}>
            </Chart>
            <div style={{ transform: 'rotate(-82deg)', top: '143px', position: 'absolute', height: isTablet ? '535px' : '335px', width: isTablet ? '520px' : '320px', display: 'flex' }}>
                {concernBubbles}
            </div>

            {/* <ConcernCarousel analyses={analyses} /> */}
            <NewConcernCarousel analyses={analyses} />
            <PreoccupationAndSolutionContainer id="PREOCCUPATION_SOLUTION_CONTAINER">
                <MainButton id="PREOCCUPATION_BUTTON" style={{ width: '160px', margin: '8px 20px' }} onClick={() => displayPreoccupations()}>
                    {t('results.preoccupation', 'Préoccupation')}
                </MainButton>
                <MainButton id="SOLUTION_BUTTON" style={{ width: '160px', margin: '8px 20px' }} onClick={() => displaySolutions()}>
                    {t('results.solution', 'Solutions')}
                </MainButton>
            </PreoccupationAndSolutionContainer>

            <CSSTransition in={inPropSolutions} addEndListener={() => null} classNames="fadeInUp">
                {display()}
            </CSSTransition>
        </ResultMainViewContainer>
    )
}

const PopUpSolutions = (props: any) => {
    const concern = useSelector((state: MyBlendState) => state.session.actualConcern)
    const concernName = useSelector((state: MyBlendState) => state.session.actualConcernName)

    const preventDefault = (e: any) => {
        e.preventDefault()
    }

    useEffect(() => {
        //trick pour bloquer le scrolling d'arriere plan
        document.getElementById("APP")?.addEventListener("touchmove", preventDefault, false)
        document.getElementById("SOLUTION")?.addEventListener("touchmove", function (e) {
            e.stopPropagation()
            e.stopImmediatePropagation()
        }, true)

        return (() => {
            document.getElementById("APP")?.removeEventListener("touchmove", preventDefault)
            document.getElementById("APP")!.style.position = ""
            document.getElementById("MAIN_COMPONENT")!.style.top = ""
            window.enableScrolling()
        })
    }, [concern])

    const closePopUp = () => {
        props.setInPropSolutions(false)
        setTimeout(() => {
            props.setPopUpSolutions([])
        }, 300);
    }

    function scrollToSmoothly(pos: any, time: any) {
        let currentPos = window.pageYOffset;
        let start: any = null;
        if (time == null) time = 500;
        pos = +pos
        time = +time;
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start;
            var progress = currentTime - start;
            if (currentPos < pos) {
                window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
            } else {
                window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
            }
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        });
    }
    return (
        //oblige de mettre display: block pour fix un bug safari :/
        <ProductDetailPopUp style={{ display: 'block', overscrollBehavior: 'none' }} id={"SOLUTION"} onClick={() => closePopUp()}>
            <CloseIcon />
            <H4 style={{ paddingBottom: '20px', paddingTop: '40px' }}>{props.t('MainView.ResultView.solutions', 'Solutions')} : {props.t('results.concern_' + setConcernNameForPreoccupation(concernName), '')}</H4>
            <ParagraphText style={{ padding: '16px 24px 20px 24px', margin: 0, whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                {ReactStringReplace(props.t('solution.' + concern, ''), '#', (match, i) => (
                    <Point key={i}>• {'\n'}</Point>))}
            </ParagraphText>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 24px 0' }}>
                <MainButton onClick={() => scrollToSmoothly(window.scrollY + 600, 500)}>
                    {props.t('results.prescription', 'Votre prescription MyBlend')}
                </MainButton>
            </div>
        </ProductDetailPopUp >

    )
}

const PopUpPreoccupation = (props: any) => {
    const concernForPreoccupation = useSelector((state: MyBlendState) => state.session.actualConcern)
    const concernName = useSelector((state: MyBlendState) => state.session.actualConcernName)
    const closePopUp = (e: any) => {
        e.stopPropagation()
        props.setInPropSolutions(false)
        window.enableScrolling()
        setTimeout(() => {
            props.setPopUpPreoccupation([])
        }, 600);
    }

    const goToSolutions = (e: any) => {
        props.setInPropSolutions(false)
        e.stopPropagation()
        //window.enableScrolling()
        setTimeout(() => {
            props.displaySolutions()
            props.setPopUpPreoccupation([])
        }, 300);
    }

    const preventDefault = (e: any) => {
        e.preventDefault()
    }

    useEffect(() => {
        //trick pour bloquer l'arriere plan
        if (document.getElementById("PREOCCUPATION_TEXT")) {
            if (document.getElementById("PREOCCUPATION_TEXT")!.innerHTML.length > 500) {
                document.getElementById("APP")?.addEventListener("touchmove", preventDefault, false)
                document.getElementById("PREOCCUPATION")?.addEventListener("touchmove", function (e) {
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                }, true)
            } else {
                window.disableScrolling()
            }
        }

        return (() => {
            document.getElementById("APP")?.removeEventListener("touchmove", preventDefault)
            document.getElementById("PREOCCUPATION")?.removeEventListener("touchmove", preventDefault)
            document.getElementById("PREOCCUPATION")?.removeEventListener("touchmove", preventDefault)
            document.getElementById("APP")?.removeEventListener("touchmove", preventDefault)

            document.getElementById("APP")!.style.position = ""
            document.getElementById("MAIN_COMPONENT")!.style.top = ""
            window.enableScrolling()

        })
    }, [concernForPreoccupation])

    return (
        //{onClick={() => closePopUp()}}
        <ProductDetailPopUp style={{ display: 'block', overscrollBehavior: 'none' }} id={"PREOCCUPATION"} onClick={(e) => closePopUp(e)}>
            <CloseIcon />
            <H4 style={{ paddingTop: '40px' }}>{props.t('results.preoccupation', 'Préoccupation')} : {props.t('results.concern_' + setConcernNameForPreoccupation(concernName), '')}</H4>
            <ParagraphText id="PREOCCUPATION_TEXT" style={{ padding: '36px 24px 10px 24px', margin: 0, whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                {ReactStringReplace(props.t('preoccupation.' + concernForPreoccupation, ''), '#', (match, i) => (
                    <Point key={i}>• {'\n'}</Point>))}
            </ParagraphText>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 24px 0' }}>
                <MainButton style={{ marginBottom: '40px' }} onClick={(e) => goToSolutions(e)}>
                    {props.t('results.solution', 'Solution')}
                </MainButton>
            </div>
        </ProductDetailPopUp>
    )
}

const ConcernBubble = (props: any) => {
    const { t } = useTranslation()
    return (
        <BubbleContent previousX={props.previousX} previousY={props.previousY} isInCarousel={props.isInCarousel}>
            <Bubble className={props.isInCarousel ? props.name : props.name + "_NOT"} color={setConcernColor(props.score)} isBig={props.isBig}>
                <CircularProgressbar value={Math.min(props.score * 100, 100)} text={Math.floor(Math.min(props.score * 100, 100)).toString()} styles={buildStyles({
                    rotation: 1,
                    strokeLinecap: 'round',
                    textSize: '30px',
                    pathColor: setConcernColor(props.score),
                    textColor: setConcernColor(props.score),
                    trailColor: 'rgba(0,0,0,0)',
                    backgroundColor: 'rgba(0,0,0,0)',
                    pathTransitionDuration: 3
                })} />
            </Bubble>
            <ConcernText style={{ fontSize: window.innerWidth > 768 ? 10 : (setConcernTranslation(t, props.name).length > 12 ? '8px' : '') }} isBig={props.isBig} color={setConcernColor(props.score)}>{setConcernTranslation(t, props.name)}</ConcernText>
        </BubbleContent>
    )
}

const NewConcernCarousel = (props: any) => {
    const [bubbles, setBubbles] = useState<Array<any>>([])
    const [imagesConcern, setImagesConcern] = useState<Array<any>>([])
    const dispatch = useDispatch()
    //let actualConcernTab:Array<any> = []
    const actualConcernTab = useRef<Array<any>>([])
    let actualIndex = useRef(0)
    let arrowsFlag = useRef(false)
    let imageUser = useSelector((state: MyBlendState) => state.session.imageUser)
    let carousel: any = null
    let carouselForImage: any = null

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5
        }
    };

    const responsiveForImage = {
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
    }

    useEffect(() => {
        let tab: Array<any> = []
        let key: number = 0
        let analysesSorted = [...props.analyses]
        analysesSorted.sort((a: any, b: any) => {
            return b.score - a.score
        })
        analysesSorted.unshift(analysesSorted.pop())
        analysesSorted.unshift(analysesSorted.pop())
        analysesSorted.unshift(analysesSorted.pop())

        //sortArray(analysesSorted)
        analysesSorted.forEach((analyse: any) => {
            actualConcernTab.current.push(analyse.name)
            tab.push(<ConcernBubble key={key} isInCarousel={true} score={analyse.score} name={analyse.name} />)
            key++
        })
        dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN, value: analysesSorted[2].name !== "hydration" ? setConcernForPreoccupation(analysesSorted[2].name) : "moisturising" })
        dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN_NAME, value: analysesSorted[2].name })

        actualConcernTab.current.push(actualConcernTab.current.shift())
        actualConcernTab.current.push(actualConcernTab.current.shift())

        setBubbles(tab)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (document.getElementsByClassName(actualConcernTab.current[actualIndex.current]).length > 2) {
            let elements = document.getElementsByClassName(actualConcernTab.current[actualIndex.current]) as HTMLCollectionOf<HTMLElement>

            for (let i = 0; i < elements.length; i++) {
                if (elements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== "true" || elements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== null) {
                    elements[i].style.width = window.innerWidth >= 768 ? '80px' : "50px"
                    elements[i].style.height = window.innerWidth >= 768 ? '80px' : "50px"
                    elements[i].style.marginTop = "-10px"
                    elements[i].style.transition = "all 400ms"
                }
            }
        }
    }, [bubbles])

    useEffect(() => {
        let tabForImages: Array<any> = []
        let key: number = 0
        let analysesSorted: Array<any> = [...props.analyses]
        analysesSorted.unshift(analysesSorted.pop())
        analysesSorted.unshift(analysesSorted.pop())
        analysesSorted.unshift(analysesSorted.pop())

        analysesSorted.forEach((analyse: any) => {
            tabForImages.push(<ConcernImg id={analyse.name} key={key} src={analyse.image ? analyse.image : imageUser} />) //analyse.image ? analyse.image : imageUser
            key++
        })
        tabForImages.push(tabForImages.shift())
        tabForImages.push(tabForImages.shift())
        //sortTabImage(tabForImages, analysesSorted)
        setImagesConcern(tabForImages)
        //eslint-disable-next-line
    }, [])

    const animateButtons = (onLeft: boolean) => {
        //creer une petite animation pour mieux voir le changement de concern
        document.getElementById('PREOCCUPATION_SOLUTION_CONTAINER')!.style.animation = "none"
        //eslint-disable-next-line
        let trick = document.getElementById("PREOCCUPATION_SOLUTION_CONTAINER")!.offsetHeight //trick pour restart l'animation
        if (onLeft) {
            document.getElementById('PREOCCUPATION_SOLUTION_CONTAINER')!.style.animation = (window.innerWidth >= 768 ? "PreoccupationAnimRightTab" : "PreoccupationAnimRight") + " 0.7s forwards"
        } else {
            document.getElementById('PREOCCUPATION_SOLUTION_CONTAINER')!.style.animation = (window.innerWidth >= 768 ? "PreoccupationAnimLeftTab" : "PreoccupationAnimLeft") + " 0.7s forwards"
        }

    }

    //let concern: any = props.analyses.find((e: any) => e.name === )
    return (
        <CarouselContentContainer>
            <GradiantImageLeft />
            <GradiantImageRight />
            <Carousel
                responsive={responsiveForImage}
                containerClass="images-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ref={(el) => (carouselForImage = el)}
                infinite={true}
                itemClass="images-item"
                swipeable={false}
            >
                {imagesConcern}
            </Carousel>
            <NextArrow className="arrows" isDisplayed={true} isPointingRight={false} onClick={() => {
                if (arrowsFlag.current) {

                }
                else {
                    animateButtons(true)
                    arrowsFlag.current = true
                    let elements = document.getElementsByClassName(actualConcernTab.current[actualIndex.current]) as HTMLCollectionOf<HTMLElement>
                    for (let index = 0; index < elements.length; index++) {
                        elements[index].style.width = ""
                        elements[index].style.height = ""
                        elements[index].style.marginTop = ""
                    }

                    if (actualConcernTab.current[actualIndex.current - 1]) {
                        actualIndex.current--
                    }
                    else {
                        actualIndex.current = 10
                    }
                    dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN, value: setConcernForPreoccupation(actualConcernTab.current[actualIndex.current]) })
                    dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN_NAME, value: actualConcernTab.current[actualIndex.current] })

                    let newElements = document.getElementsByClassName(actualConcernTab.current[actualIndex.current]) as HTMLCollectionOf<HTMLElement>
                    for (let i = 0; i < newElements.length; i++) {
                        if (newElements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== "true" || newElements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== null) {
                            newElements[i].style.width = window.innerWidth >= 768 ? '80px' : "50px"
                            newElements[i].style.height = window.innerWidth >= 768 ? '80px' : "50px"
                            newElements[i].style.marginTop = "-10px"
                            newElements[i].style.transition = "all 400ms"
                        }
                    }
                    carousel.previous()
                    carouselForImage.previous()
                    setTimeout(() => {
                        arrowsFlag.current = false
                    }, 700)
                }
            }} />
            <NextArrow className="arrows" isDisplayed={true} isPointingRight={true} onClick={() => {
                if (arrowsFlag.current) {

                }
                else {
                    arrowsFlag.current = true
                    animateButtons(false)
                    let elements = document.getElementsByClassName(actualConcernTab.current[actualIndex.current]) as HTMLCollectionOf<HTMLElement>
                    for (let index = 0; index < elements.length; index++) {
                        elements[index].style.width = ""
                        elements[index].style.height = ""
                        elements[index].style.marginTop = ""
                    }

                    if (actualConcernTab.current[actualIndex.current + 1]) {
                        actualIndex.current++
                    }
                    else {
                        actualIndex.current = 0
                    }
                    dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN_NAME, value: actualConcernTab.current[actualIndex.current] })

                    dispatch({ type: ActionTypes.SET_ACTUAL_CONCERN, value: setConcernForPreoccupation(actualConcernTab.current[actualIndex.current]) })
                    let newElements = document.getElementsByClassName(actualConcernTab.current[actualIndex.current]) as HTMLCollectionOf<HTMLElement>
                    for (let i = 0; i < newElements.length; i++) {
                        if (newElements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== "true" || newElements[i].parentElement?.parentElement?.getAttribute("aria-hidden") !== null) {
                            newElements[i].style.width = window.innerWidth >= 768 ? '80px' : "50px"
                            newElements[i].style.height = window.innerWidth >= 768 ? '80px' : "50px"
                            newElements[i].style.marginTop = "-10px"
                            newElements[i].style.transition = "all 400ms"
                        }
                    }
                    carousel.next();
                    carouselForImage.next()
                    setTimeout(() => {
                        arrowsFlag.current = false
                    }, 700)
                }
            }} />
            <Carousel
                containerClass="bubbles-container"
                responsive={responsive}
                infinite={true}
                swipeable={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                ref={(el) => (carousel = el)}
            >
                {bubbles}
            </Carousel>
        </CarouselContentContainer>
    )
}

export default ConcernView
