import styled from 'styled-components'
import { MainColor } from './Theme'

export const MainButton = styled.button`
    margin-top: 20px;
    min-width: 120px;
    height: 42px;
    display: flex;
    transition: all 500ms;
    align-items: center;
    justify-content: center;
    background: ${MainColor} 0% 0% no-repeat padding-box;
    border-radius: 40px;
    padding : 12px 24px;
    box-sizing: border-box;
    border-width: 0;
    text-align: center;
    font: normal normal normal 14px/22px GothamMedium;
    letter-spacing: 0.28px;
    color: #FFFFFF;
    opacity: 1;
`;

export const MyBInput = styled.input`
    border-radius: 50px;
    display: flex;
    position: relative;
    opacity: 1;
    font: normal normal 100 16px/24px GothamMedium;
    letter-spacing: 0px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-top: 22px;
    padding-bottom: 22px;
    color: #1B4175;
    border-width: 0;
    min-width: 300px;
    max-width: 400px;
    padding-left: 24px;
    box-sizing: border-box;
`;

export const BeginButton = styled(MainButton)`
    margin-bottom: 64px;
    position: relative;
`;

export const CheatCode = styled(MainButton)`
    position: absolute;
    width: 124px;
    height: 124px;
    bottom: 0;
    right:0;
    opacity: 0;
    z-index: 3;
`

export const QuestButton = styled.button<{ isSelected: boolean, $isLong: boolean }>`
    background: ${props => props.isSelected ? '#1B4175' : '#FFFFFF'};
    border-radius: 50px;
    display: flex;
    position: relative;
    opacity: 1;
    padding: ${props => props.$isLong ? "16px 32px 16px 16px" : "16px"};
    gap: 16px;
    text-align: center;
    max-height: ${props => props.$isLong ? "50px" : "56px"};
    flex: 1 1 43%;
    max-width: ${props => props.$isLong && "calc(50% - 12px)"};
    font: ${props => props.$isLong ? "normal normal 100 14px/18px GothamMedium" : "normal normal 100 16px/24px GothamMedium"};
    letter-spacing: 0px;
    align-items: center;
    justify-content: center;
    color: ${props => props.isSelected ? '#FFFFFF' : '#1D1D1B'};
    border-width: 0;
    transition: 1000ms;
`;

export const OrderIcon = styled.div<{$isLong?: boolean}>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.$isLong ? "18px" : "24px"};
    width: ${props => props.$isLong ? "18px" : "24px"};
    border-radius: 24px;
    background-color: white;
    color: #1B4175;
    right: ${props => props.$isLong ? "10px" : "24px"};
    margin: auto;
    font-size: 12px;
    top: 0;
    bottom: 0;
`;

export const BeigeButton = styled.div`
    height: 42px;
    padding : 4px 16px;
    text-align: center;
    position: relative;
    background-color: #F4F3F0;
    border-radius: 40px;
    color: #1D1D1B;
    letter-spacing: 0.28px;
    font: normal normal 900 14px/22px GothamMedium;
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const BuyButton = styled.button`
    text-align: center;
    color: #1D1D1B;
    font: normal normal 900 14px/27px Gothambold;
    border: none;
    border-bottom-style: groove;
    border-bottom-width: 1px;
    border-bottom-color: black;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
`

export const DisplayProductButton = styled.button`
    width: 28px;
    height: 28px;
    border: 1px solid #1B4175;
    color: #1B4175;
    font: normal normal medium 22px/27px GothamMedium;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 22px;
    margin-top: 24px;
`

export const EmailInput = styled.input`
    padding: 14px 24px 14px 24px;
    background: white;
    border: solid;
    border-radius: 30px;
    border-width: 1px;
    border-color: #1B4175;
    font: normal normal 500 14px/22px GothamMedium;
    min-width: 200px;
`

export const TapButtonToSwitch = styled.button`
    background: transparent;
    height: 100%;
    position: absolute;
    width: 100%;
    border: none;
    z-index: 20;
`

export const GlitchyButton = styled.div`
    padding-bottom: 12px;
    padding-top: 12px;
    height: 42px;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${MainColor} 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding-left: 24px;
    padding-right: 24px;
    border-width: 0;
    text-align: center;
    font: normal normal normal 14px/22px GothamMedium;
    letter-spacing: 0.28px;
    color: #FFFFFF;
`