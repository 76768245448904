import { useDispatch, useSelector } from "react-redux"
import * as ActionType from '../Constants/ActionsTypes'
import { BeginButton } from '../Styles/Buttons'
import { ComponentContainer, ParagraphContainer, PopOver } from '../Styles/Containers'
import { H1, H2, H3, H4, ParagraphText, PrivacyText, UnderLined } from '../Styles/Texts'
import { CameraIcon, BubbleIcon, LockIcon } from '../Styles/Images'
import { useTranslation } from 'react-i18next'
import { useEffect } from "react"
import '../Assets/animations.css'
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'
import reactStringReplace from "react-string-replace"
import { AnalyticsObject } from "../Analytics/analytics"
import { MyBlendState } from "../type"

const PopOverComponent = () => {
    const { t } = useTranslation()
    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)

    const closePopUp = () => {
        window.enableScrolling()
        analyticsObject.sendAnalyticsEvent("privacy_policy_closed", "");
        document.getElementById('POPOVER')!.style.animation = 'PopOverAnim 0.3s forwards normal'
    }

    // useEffect(() => {
    //     window.disableScrolling()
    // }, [])

    document.body.style.backgroundColor = 'white'

    const handleLink = () => {
        if(window.market === "uk"){
            return "https://my-blend.co.uk/en/privacy-policy/"
        }
        return t("onboarding.privacyLink")
    }

    return (
        <PopOver id={'POPOVER'}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "24px" }}>
                <LockIcon />
            </div>
            <H4>
                {t('onboarding.protectData', 'My Blend protège vos données')}
            </H4>
            <PrivacyText>
                {t('onboarding.privacyPolicy', 'Les informations recueillies dans le cadre de ce diagnostic font l’objet d’un traitement informatique destiné à My Blend et à ses prestataires afin de vous orienter vers des soins adaptés à votre situation. Le degré de généralité des questions ne révèle aucune information sur votre état de santé. Nous ne conservons ni votre photo, ni vos réponses, ni votre diagnostic personnalisé à l’issue de l’analyse.')}
                {t('onboarding.privacyPolicyBottom', 'Pour en savoir plus, veuillez consulter notre politique de respect de la vie privée en')} {'\r'}
                <UnderLined onClick={() => window.open(handleLink(), "_blank")}>{t('onboarding.privacyPolicyLink', 'cliquant ici')}</UnderLined>
            </PrivacyText>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <BeginButton onClick={() => closePopUp()}> {t('onboarding.accept', 'J\'accepte')} </BeginButton>
            </div>
        </PopOver>
    )
}

const OnBoardingView = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [inProp, setInProp] = useState<boolean>(false)

    // enable/disable les transitions
    useEffect(() => {
        //document.getElementById("POPOVER")!.style.animation = "PopOverUp 1s forwards"
        setTimeout(() => {
            if (document.getElementById("POPOVER")) {
                document.getElementById("POPOVER")!.style.bottom = "-5%"
            }
        }, 500);
        //setInProp(true)
    }, [])

    const nextStep = () => {
        setInProp(false)
        dispatch({ type: ActionType.SET_STEP, value: 1 })
    }

    return (
        <CSSTransition in={inProp} classNames="swipeToRight" addEndListener={() => null}>
            <ComponentContainer style={{ backgroundColor: 'white' }}>
                <H1> {t('tips.title', 'Votre prescription beauté en moins d’une minute')}</H1>
                <CameraIcon />
                <H2>{t('tips.step', 'Étape')} 1</H2>
                <H3>{t('tips.1.title', 'Prise de photo automatique et analyse de vos signes cutanés.')}</H3>
                <ParagraphContainer style={{ width: '80%' }}>
                    <ParagraphText style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}>
                        {reactStringReplace(t('tips.1.body', '• Avoir le visage dégagé, sans bijoux, sans lunettes, ni maquillage.\r \n• Être dans un lieu bien exposé à la lumière naturelle.\r \n• Avoir une expression neutre.\r \n• Placer son visage dans l’ovale, en respectant la distance et la hauteur des yeux.'), '•', (match, i) => (
                            <span key={i} style={{ color: '#1B4175' }}>{match}</span>))}
                    </ParagraphText>
                </ParagraphContainer>
                <BubbleIcon />
                <H2>{t('tips.step', 'Étape')} 2</H2>
                <H3>{t('tips.2.title', '11 questions afin d’évaluer votre bio-environnement.')}</H3>
                <BeginButton onClick={() => nextStep()}>{t('tips.start', 'Commencer votre Skin Diag')}</BeginButton>
                <PopOverComponent />
            </ComponentContainer>
        </CSSTransition>

    )
}

const OnBoardingComponent = () => {
    return (
        <OnBoardingView />
    )
}

export default OnBoardingComponent