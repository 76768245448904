import { useTranslation } from "react-i18next"
import { BeigeButton, MainButton, OrderIcon, BuyButton, DisplayProductButton } from "../../Styles/Buttons"
import { ComponentContainer, ProductCellContainer, SmallProductCellContainer, TextProductCellContainer, ProductsContainer, ProductDetailPopUp } from "../../Styles/Containers"
import { H0, ThreeConcernText, ParagraphText, ProductName, VolumeAndPrinceText, H5 } from "../../Styles/Texts"
import { PreoccupationText } from "./ConcernView"
import styled from 'styled-components'
import { MyBlendState } from "../../type"
import { useDispatch, useSelector } from 'react-redux'
import { setConcernTranslation } from "./ConcernView"
import { useEffect, useState } from "react"
import { TFunction } from "i18next"
import ProductDetailView from './ProductDetailView'
import { CSSTransition } from 'react-transition-group'
import CheckBox from "rc-checkbox"
import * as ActionTypes from '../../Constants/ActionsTypes'
import { setAnswerTranslation } from '../../Constants/QuestsConstants'
import { AnalyticsObject } from "../../Analytics/analytics"
import { displayCurrencySymbol } from "../../Constants/Functions"

export function importAll(r: any) {
    let images: any = {};
    r.keys().map((item: any, index: any) => { return images[item.replace('./', '')] = r(item); });
    return images;
}

export const setGammeTranslation = (gamme: string, t: TFunction) => {
    switch (gamme) {
        case 'expertVisage':
            return t('gamme.expertVisage', 'Expert Visage')
        case 'expertCorps':
            return t('gamme.expertCorps', 'Expert Corps')
        case 'treatementEssence':
            return t('gamme.treatementEssence', 'Traitement essence')
        case 'nutricosmetic':
            return t('gamme.nutricosmetic', 'Nutri-cosmetic')
        case "no-makeup":
        case 'noMakeUp':
            return t('gamme.noMakeUp', 'No make up')
        case 'supercharged':
            return t('gamme.supercharged', 'Supercharged')
        case "moisturisers":
        case 'essential':
            return t('gamme.essential', 'Essential')
        case 'cleansing':
            return t('gamme.cleansing', 'Cleansing')
        case 'masks':
        case 'masques':
            return t('gamme.masques', 'Masques')
        case "face-serums":
        case 'superserums':
            return t('gamme.superserums', 'Superserums')
        case 'embellisseur':
            return t('gamme.embellisseur', 'Embellisseur')
        case 'soin-jour-et-nuit':
            return t('gamme.soin-jour-et-nuit', 'Soins jour et nuit')
        case 'essence-de-soin':
            return t('gamme.essence-de-soin', 'Essence de soin')
        case "cleansers-and-makeup-removers":
        case 'nettoyants-et-demaquillants':
            return t('gamme.nettoyants-et-demaquillants', 'Nettoyants et démaquillants')
        case 'body':
        case 'corps':
            return t('gamme.corps', 'Corps')
        case 'tech':
            return t('gamme.tech', 'Masque LED')
        case "exfoliators-and-peels":
        case 'exfoliants-et-peeling':
            return t('gamme.exfoliants-et-peeling', 'Exfoliants et peeling')
        case "eye-care":
        case 'contour-des-yeux':
            return t('gamme.contour-des-yeux', 'Contour des yeux')
        case 'accessoires':
            return t('gamme.accessoires', 'Accessoires')
        case "uv-protection":
        case 'uv':
            return t('gamme.uv', 'UV')
        case "food-supplements":
        case 'complements-alimentaires':
            return t('gamme.complements-alimentaires', 'Compléments alimentaires')
        case 'uncategorized':
            return t('gamme.uncategorized', ' ')
        default:
            return gamme
    }
}

export const castAnalysesConcernForAnswers = (analyse: string): string => {
    switch (analyse) {
        case 'HYDRATATION':
            return 'hydration'
        case 'WRINKLES_UNDER_EYE':
            return 'wrinkles'
        case 'DARK_SPOTS':
            return 'darkspots'
        case 'HOLLOW_CHEEKS':
            return 'firmness'
        case 'LOWER_FACE_PTOSIS':
            return 'firmness'
        case 'TEAR_THROUGH':
            return 'firmness'
        default:
            return analyse.toLowerCase()
    }
}

const OrderConcernIcon = styled(OrderIcon)`
    background-color: #1B4175;
    position: relative;
    box-sizing: border-box;
    color: white;
    margin: 0;
    margin-right: 12px;
    right: 0;
`
const ProductsView = () => {
    const { t } = useTranslation()
    const session = useSelector((state: MyBlendState) => state.session)
    const analyses = useSelector((state: MyBlendState) => state.session.analyses)
    const panier = useSelector((state: MyBlendState) => state.session.recommendedProducts)
    const questions = useSelector((state: MyBlendState) => state.session.questions)
    const images = importAll(require.context('../../Assets/Images Produits/', false, /\.(png|jpe?g|svg)$/));
    const firstLaunchResult: AnalyticsObject = useSelector((state: MyBlendState) => state.session.firstLaunchResult)

    for (const image in images) {
        const newImage = new Image();
        newImage.src = image;
        window.image = newImage
    }

    let preoccupationTab: Array<any> = []

    console.log("PREO : " + questions[5][0])
    console.log("PREO : " + castAnalysesConcernForAnswers(analyses[0].name))
    console.log("PREO : " + castAnalysesConcernForAnswers(analyses[1].name))
    if (questions[5][0] !== castAnalysesConcernForAnswers(analyses[0].name)) {
        preoccupationTab.push(setAnswerTranslation(questions[5][0], t))
        preoccupationTab.push(setConcernTranslation(t, analyses[0].name))
        preoccupationTab.push(setConcernTranslation(t, analyses[1].name))
    } else {
        preoccupationTab.push(setAnswerTranslation(questions[5][0], t))
        preoccupationTab.push(setConcernTranslation(t, analyses[1].name))
        preoccupationTab.push(setConcernTranslation(t, analyses[2].name))
    }

    if (preoccupationTab[0] === preoccupationTab[2]) {
        preoccupationTab[2] = setConcernTranslation(t, analyses[2].name)
    }

    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)
    if (firstLaunchResult) {
        for (let i = 0; i < panier.length; i++) {
            panier[i].forEach((product: any) => {
                analyticsObject.sendAnalyticsEvent("reco_product", { ["panier" + (i + 1).toString()]: product.sku })
            });
        };
        session.firstLaunchResult = false;
    }


    return (
        <ComponentContainer style={{ backgroundColor: 'white', alignItems: 'stretch' }}>
            <H0 paddingTop={30} style={{ textAlign: 'center' }}>
                {t('prescription.title', 'Votre prescription')}
            </H0>
            <PreoccupationText style={{ paddingBottom: '16px' }}>
                {t('prescription.subtitle', 'Vos principales préoccupations sont :')}
            </PreoccupationText>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '200px', justifyContent: 'center' }}>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <OrderConcernIcon style={{ marginRight: 0 }}>1</OrderConcernIcon>
                    </div>
                    <ThreeConcernText>{preoccupationTab[0]}</ThreeConcernText>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '200px', justifyContent: 'center' }}>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <OrderConcernIcon style={{ marginRight: 0 }}>2</OrderConcernIcon>
                    </div>
                    <ThreeConcernText>{preoccupationTab[1]}</ThreeConcernText>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '200px', justifyContent: 'center' }}>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <OrderConcernIcon style={{ marginRight: 0 }}>3</OrderConcernIcon>
                    </div>

                    <ThreeConcernText>{preoccupationTab[2]}</ThreeConcernText>
                </div>
            </div>

            <MainProductsPanel products={panier[0]} images={images} />
            <ExpertProductsPanel products={panier[1]} images={images} />
            <ComplementaryProductsPanel products={panier[2]} images={images} />
        </ComponentContainer>
    )
}

const MainProductsPanel = (props: any) => {
    const { t } = useTranslation()
    const [products, setProducts] = useState<Array<any>>([])
    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)

    const buyMyEssentials = () => {
        var tab: Array<any> = []
        props.products.forEach((product: any) => {
            tab.push(product.sku)
            analyticsObject.sendAnalyticsEvent("buy_my_essentials", "")
            analyticsObject.sendAnalyticsEvent("add_to_cart", { "sku": product.sku, "price": product.price[window.market] })
        });
        let data = {
            messageType: "basket",
            products: tab
        };
        console.log(data)
        window.parent.postMessage(data, "*")
    }

    useEffect(() => {
        let tab: Array<any> = []
        let key: number = 0
        let sortedArray: Array<any> = []
        console.log(props.products)

        if (props.products) {
            sortedArray.push(props.products.find((e: any) => ["80086635", "80086630"].includes(e.sku)))
            sortedArray.push(props.products.find((e: any) => ["80086699", "80086691", "80086695", "80086675", "80086687", "80086679", "80086683"].includes(e.sku)))
            sortedArray.push(props.products.find((e: any) => ["80086699", "80086691", "80086695", "80086675", "80086687", "80086679", "80086683", "80098750"].includes(e.sku) && !sortedArray.includes(e)))
            sortedArray.push(props.products.find((e: any) => ["80086673", "80086669", "80086671"].includes(e.sku)))
        }
        console.log(sortedArray)

        if (sortedArray[0]) {
            sortedArray.forEach((product: any) => {

                if (product !== undefined) {

                    tab.push(<ProductCell product={product} key={key} />)
                } else {
                    //alert("problem avec la reco")
                }
                key++
            });
            setProducts(tab)
        }

    }, [props.products, props.images])

    return (
        <ProductsContainer>
            <BeigeButton>
                {t('prescription.essentials', 'Mes essentiels')}
            </BeigeButton>
            <ParagraphText style={{ marginLeft: '20px', marginRight: '20px' }}>
                {t('prescription.essentials_subtitle', 'Réponse aux principaux besoins de ma peau')}
            </ParagraphText>
            {products}
            <MainButton style={{ maxWidth: '284px', height: 'initial' }} onClick={() => buyMyEssentials()}>
                {t('prescription.essentials_buy', 'Acheter mes essentiels')}
            </MainButton>
        </ProductsContainer>
    )
}

const ExpertProductsPanel = (props: any) => {
    const { t } = useTranslation()
    const [products, setProducts] = useState<Array<any>>([])

    const showOrHideProducts = () => {
        let tab: Array<any> = []
        let key: number = 0
        if (products.length === 0) {
            props.products.forEach((product: any) => {
                tab.push(<SmallProductCell product={product} key={key} />)
                key++
            });
            setProducts(tab)
        } else {
            setProducts([])
        }
    }

    return (
        <ProductsContainer>
            <BeigeButton style={{ textAlign: 'center' }}>
                {t('prescription.experts', 'Mes experts')}
            </BeigeButton>
            <ParagraphText style={{ marginLeft: '20px', marginRight: '20px' }}>
                {t('prescription.experts_subtitle', 'Découvrir les produits selectionnés pour moi et adaptés à ma routine.')}
            </ParagraphText>
            <DisplayProductButton onClick={() => showOrHideProducts()}>
                {products.length === 0 ? '+' : '-'}
            </DisplayProductButton>
            {products}
        </ProductsContainer>
    )
}

const ComplementaryProductsPanel = (props: any) => {
    const { t } = useTranslation()
    const [products, setProducts] = useState<Array<any>>([])
    //const cacheArray = useState<Array<any>>([])

    const showOrHideProducts = () => {
        let tab: Array<any> = []
        let key: number = 0
        if (products.length === 0) {
            props.products.forEach((product: any) => {
                tab.push(<SmallProductCell product={product} key={key} />)
                key++
            });
            setProducts(tab)
        } else {
            setProducts([])
        }
    }

    return (
        <ProductsContainer style={{ marginBottom: '50px' }}>
            <BeigeButton>
                {t('prescription.complementary', 'Mes complémentaires')}
            </BeigeButton>
            <ParagraphText style={{ marginLeft: '20px', marginRight: '20px' }}>
                {t('prescription.complementary_subtitle', 'Aller plus loin et poursuivre la découverte des produits MyBlend.')}
            </ParagraphText>
            <DisplayProductButton onClick={() => showOrHideProducts()}>
                {products.length === 0 ? '+' : '-'}
            </DisplayProductButton>
            {products}
        </ProductsContainer>
    )
}

export const ProductCell = (props: any) => {
    const { t } = useTranslation()
    const [detailPopUp, setDetailPopUp] = useState<Array<any>>([])
    const [inProp, setInProp] = useState<boolean>(false)
    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)

    const displayDetail = () => {
        if (detailPopUp.length === 0) {
            window.disableScrolling()
            let tab: Array<any> = []
            tab.push(<ProductDetailView key={0} product={props.product} setInProp={setInProp} setDetailPopUp={setDetailPopUp} />)
            setDetailPopUp(tab)
            setInProp(true)
        }
    }

    const display = () => {
        if (detailPopUp[0]) {
            return detailPopUp[0]
        }
        else {
            return (<div></div>)
        }
    }

    const buyButton = (e: any) => {
        e.stopPropagation()
        let data = {
            messageType: "basket",
            products: props.product.sku
        };

        analyticsObject.sendAnalyticsEvent("add_to_cart", { "sku": props.product.sku, "price": props.product.price[window.market] })

        console.log(data)
        window.parent.postMessage(data, "*")
    }

    const handlePrice = () => {
        if (props.product.price[window.market]) {
            return props.product.price[window.market]
        }

        if (window.market === "uk") {
            return props.product.price["EMEA"]
        }

        return "--"
    }

    const handleImage = () => {
        try {
            let src = require("../../Assets/Images Produits/" + props.product.sku + ".png")
            return src
        } catch (error) {
            return require("../../Assets/Images Produits/80086621.png")
        }
    }

    return (
        <ProductCellContainer onClick={() => displayDetail()}>
            <img style={{ width: '100px', height: '100px' }} alt={props.product.image ? props.product.image : ''} src={handleImage()} />
            <ProductName style={{ padding: '0px 24px' }}>
                {props.product.name[window.lang] ? props.product.name[window.lang] : props.product.name["EnUs"]}
            </ProductName>
            <ParagraphText style={{ marginLeft: '0', marginRight: '0', paddingLeft: '24px', paddingRight: '24px' }}>
                {props.product.shortDesc[window.lang] ? props.product.shortDesc[window.lang] : props.product.shortDesc["EnUs"]}
            </ParagraphText>
            <VolumeAndPrinceText>
                {props.product.volume[window.unity] ? props.product.volume[window.unity] : props.product.volume["liter"]} - {handlePrice()}{displayCurrencySymbol()}
            </VolumeAndPrinceText>

            <BuyButton onClick={(e) => buyButton(e)}>
                {t('prescription.buy', 'Acheter')}
            </BuyButton>
            <CSSTransition in={inProp} classNames="fadeInUp" addEndListener={() => null}>
                {display()}
            </CSSTransition>
        </ProductCellContainer>
    )
}

export const SmallProductCell = (props: any) => {
    const { t } = useTranslation()
    const [inProp, setInProp] = useState<boolean>(false)
    const [detailPopUp, setDetailPopUp] = useState<Array<any>>([])
    const dispatch = useDispatch()
    const ritualProductsFromStore = useSelector((state: MyBlendState) => state.session.ritualKitProducts)
    const [warningPopUp, setWarningPopUp] = useState<Array<any>>([])
    const [warningInProp, setWarningInProp] = useState<boolean>(false)
    const [shityTweak, setShityTweak] = useState<boolean>(false) //sert juste a update le composant parce que le store le fait pas (??)

    useEffect(() => {
        console.log(props.product)
        if ((props.product.category === "superserums" || ["80086633", "80089643"].includes(props.product.sku)) && !ritualProductsFromStore.some((e: any) => e.sku === props.product.sku)) {
            dispatch({ type: ActionTypes.UPDATE_RITUAL_KIT, value: props.product })
        }
        //eslint-disable-next-line
    }, [])

    const displayDetail = () => {
        if (detailPopUp.length === 0) {
            window.disableScrolling()
            let tab: Array<any> = []
            tab.push(<ProductDetailView key={0} product={props.product} setInProp={setInProp} setDetailPopUp={setDetailPopUp} />)
            setDetailPopUp(tab)
            setInProp(true)
        }
    }

    const display = () => {
        if (detailPopUp[0]) {
            return detailPopUp[0]
        }
        else {
            return (<div></div>)
        }
    }

    const displayWarningPopUpFunction = () => {
        if (warningPopUp[0]) {
            return warningPopUp[0]
        }
        else {
            return <div></div>
        }
    }

    const displayWarningPopUp = () => {
        if (warningPopUp.length === 0) {
            window.disableScrolling()
            let tab: Array<any> = []
            tab.push(<PopUpWarning key={0} />)
            setWarningPopUp(tab)
            setWarningInProp(true)
        }
    }

    // const handleRitualProducts = () => {

    //     if (ritualProductsFromStore.length === 6) {
    //         dispatch({ type: ActionTypes.UPDATE_RITUAL_KIT, value: props.product })
    //         isChecked ? setIsChecked(false) : setIsChecked(true)
    //     }else {
    //         setIsChecked(false)
    //         dispatch({ type: ActionTypes.UPDATE_RITUAL_KIT, value: -1 })
    //         displayWarningPopUp()
    //     }

    // }

    const handleRitualProducts = () => {
        dispatch({ type: ActionTypes.UPDATE_RITUAL_KIT, value: props.product })
        if (ritualProductsFromStore.length > 6) {
            displayWarningPopUp()
            dispatch({ type: ActionTypes.UPDATE_RITUAL_KIT, value: -2 })
        }
        setShityTweak(!shityTweak)
    }

    const PopUpWarning = (props: any) => {
        const close = (e: any) => {
            e.stopPropagation()
            setWarningInProp(false)
            window.enableScrolling()
            setTimeout(() => {
                setWarningPopUp([])
            }, 300);
        }
        return (
            <ProductDetailPopUp onClick={(e) => close(e)}>
                <ParagraphText style={{ margin: '40px 24px 20px 24px' }}>
                    {t('ResultView.ProductsView.warning2', 'Vous ne pouvez sélectionner que 3 produits complémentaires.')}
                </ParagraphText>
                <MainButton style={{ margin: '0 0 40px 0' }}>
                    {t('ResultView.ProductsView.continue', 'Continuer la sélection')}
                </MainButton>
            </ProductDetailPopUp>
        )
    }

    const handleImage = () => {
        try {
            let src = require("../../Assets/Images Produits/" + props.product.sku + ".png")
            return src
        } catch (error) {
            return require("../../Assets/Images Produits/80086621.png")
        }
    }

    return (
        <SmallProductCellContainer style={{ marginTop: '8px', width: '100%', justifyContent: 'flex-start' }} onClick={() => displayDetail()}>
            <ImgContainer>
                <img style={{ minWidth: '100%', minHeight: '100%', objectFit: 'contain' }} alt={props.product.image} src={handleImage()} />
            </ImgContainer>
            <TextProductCellContainer>
                <ProductName style={{ textAlign: 'left' }}>
                    {props.product.name[window.lang] ? props.product.name[window.lang] : props.product.name["EnUs"]}
                </ProductName>
                <ParagraphText style={{ marginLeft: '0', marginRight: '0', font: 'normal normal 500 13px/20px GothamBook', textAlign: 'left' }}>
                    {props.product.shortDesc[window.lang] ? props.product.shortDesc[window.lang] : props.product.shortDesc["EnUs"]}
                </ParagraphText>
                {!props.isInRitualView ?
                    <BuyButton>
                        {t('results.learn_more', 'En savoir plus.')}
                    </BuyButton> :
                    <H5 style={{ margin: '10px 0 15px 0' }}>
                        {t('results.learn_more', 'En savoir plus.')}
                    </H5>
                }

                {props.isInRitualView && (props.product.category === "superserums" || props.product.category.includes("derma") || props.product.category.includes("soin-jour-et-nuit") || ["80086633", "80089643"].includes(props.product.sku)) ?
                    <H5 style={{ color: '#1b4175' }}>
                        {t('ritual.included', 'Inclus')}
                    </H5>
                    :
                    props.isInRitualView &&
                    <label onClick={(e) => { e.stopPropagation() }}>
                        <CheckBox
                            onChange={handleRitualProducts}
                            checked={ritualProductsFromStore.some((e: any) => e.sku === props.product.sku)}
                        />
                        {/* eslint-disable-next-line*/}
                        <a style={{ font: 'normal normal 500 13px/20px GothamMedium', color: '#1b4175' }}>{t('ritual.add', 'Ajouter au set')}</a>
                    </label>
                }
            </TextProductCellContainer>
            <CSSTransition in={inProp} classNames="fadeInUp" addEndListener={() => null}>
                {display()}
            </CSSTransition>
            <CSSTransition in={warningInProp} classNames="fadeInUp" addEndListener={() => null}>
                {displayWarningPopUpFunction()}
            </CSSTransition>
        </SmallProductCellContainer>
    )
}

export const ImgContainer = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    padding: 6px;
    margin-left: 18px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
`

export default ProductsView