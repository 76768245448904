
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from './locales/ru.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import es from './locales/es.json'
import en from './locales/en.json'
import it from './locales/it.json'
import pt from './locales/pt.json'


const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'cookie', 'localStorage'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de: {
        translation: de
      },
      ru: { // translation is the default namespace
        translation: ru
      },
      fr: {
        translation: fr
      },
      es: {
        translation: es
      },
      en: {
        translation: en
      },
      it: {
        translation: it
      },
      pt: {
        translation: pt
      }},
      
    nonExplicitSupportedLngs: false,
    fallbackLng: 'fr',
    debug: true,
    whitelist: ['ru', 'fr', 'es', 'en', 'pt', 'it', 'fr-FR', 'ru-RU', 'en_US', 'es_ES', 'fr_FR', 'de', 'de-CH', 'de_CH', 'de_DE', 'de-DE'], // available languages for browser dector to pick from
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;