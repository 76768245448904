import styled, { createGlobalStyle } from 'styled-components';
import GothamBook from '../Assets/gothamBook.ttf';
import GothamBold from '../Assets/GothamBold.otf';
import GothamBlack from '../Assets/GothamBlack.otf';
import GothamMedium from '../Assets/GothamMedium.otf';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'GothamBook';
        src: url(${GothamBook}) format("truetype");
    }

    @font-face {
        font-family: 'GothamMedium';
        src: url(${GothamMedium}) format("opentype");
    }

    @font-face {
        font-family: 'GothamBold';
        src: url(${GothamBold}) format("opentype");
    }

    @font-face {
        font-family: 'GothamBlack';
        src: url(${GothamBlack}) format("opentype");
    }

    body {
        background-color: #F4F3F0;
      }
`;

export const H0 = styled.p<{ paddingTop: number }>`
    font: normal normal bold 28px/30px GothamBold;
    letter-spacing: 0.56px;
    padding-top: ${props => props.paddingTop + 'px'};
    margin: 0;
`

export const H1 = styled.p`
    font: normal normal bold 20px/28px GothamBold;
    text-align: center;
    letter-spacing: 0.4px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0;
`;

export const H2 = styled.p`
    padding-top: 20px;
    letter-spacing: var(--unnamed-character-spacing-3-5);
    text-align: center;
    font: normal normal 900 14px/20px GothamBlack;
    letter-spacing: 3.5px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
`;

export const H3 = styled.p`
    padding-top: 20px;
    text-align: center;
    font: normal normal 900 16px/24px GothamMedium;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
`;

export const H4 = styled.p`
    font: normal normal 900 14px/20px GothamBlack;
    letter-spacing: 3.5px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    text-align: center;
`;

export const H5 = styled.p`
    font: normal normal 500 13px/20px GothamMedium;
    letter-spacing: 0;
    margin: 0;
    text-align: center;
`

export const ParagraphText = styled.p`
    text-align: center;
    font: normal normal normal 16px/24px GothamBook;
    letter-spacing: 0px;
    color: #1D1D1B;
    opacity: 1;
    margin: 0;
`;

export const Point = styled.a`
    color: #1B4175;
    opacity: 1;
`;

export const PrivacyText = styled(ParagraphText)`
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    text-align: center;
    font-size: ${window.innerWidth < 380 && '14px'};
`;

export const CameraText = styled(H4)`
    position: absolute;
    top: 70px;
    z-index: 5;
`;

export const ConcernText = styled.p<{ color: string, isBig: boolean }>`
    font: ${props => window.innerWidth > 768 ? "normal normal 500 14px/16px GothamMedium" : "normal normal 500 10px/12px GothamMedium"};
    position: absolute;
    top: ${window.innerWidth > 768 ? '65px' : '35px'};
    width: 66px;
    text-align: center;
    z-index: 4;
    color: ${props => props.color};
`

export const ThreeConcernText = styled.p`
    font: normal normal 500 16px/24px GothamMedium;
    color: #1B4175;
    width: 120px;
    margin: 8px;
`

export const ProductName = styled.p`
    text-align: center;
    font: normal normal 900 12px/18px GothamMedium;
    letter-spacing: 3px;
    color: #1D1D1B;
    text-transform: uppercase;
`

export const VolumeAndPrinceText = styled.p`
    text-align: center;
    font: normal normal 900 12px/18px GothamBold;
    color: #1D1D1B;
    letter-spacing: 0.24px;
`

export const BluePoint = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 10px;
    box-sizing: border-box;
    border: solid 6px;
    border-color: #1B4175;
`

export const CoolDown = styled.p`
    position: absolute;
    top: 100px;
    right: 24px;
    z-index: 10;
    height: 80px;
    display:flex;
    align-items:center;
    text-align: center;
    font: normal normal bold 30px/28px GothamBold;
    line-height: 42px
`

export const UnderLined = styled.label`
      text-decoration: underline;
`

export const Retour = styled.a`
    font: normal normal 500 14px/24px GothamMedium;
    color: #1B4175;
    position: fixed;
    left: 40px;
    top: 20px;
    z-index: 5;
`
export const Recommencer = styled.a`
    font: normal normal 500 14px/24px GothamMedium;
    color: #1B4175;
    text-decoration: underline;
    position: relative;
    width: 200px;
    left: 40px;
    top: 28px;
    z-index: 5;
`

export const WarningText = styled.a`
    font: normal normal 500 12px/20px GothamMedium;
    color: #D1180F;
    position: relative;
    top: 32px;
`

export const WarningTextInBar = styled.a<{$isGood: boolean}>`
    font: normal normal 500 14px/20px GothamMedium;
    padding-top: 10px;
    color: ${props => props.$isGood ? "#3DC563" : "#D1180F"};
`