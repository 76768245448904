import { ComponentContainer, ProductsContainer, BottomBuyBar, ProductDetailPopUp, QuestionNavBar } from '../../Styles/Containers'
import { H0, H4, ParagraphText, Retour, VolumeAndPrinceText } from '../../Styles/Texts'
import { BeigeButton, DisplayProductButton, MainButton } from '../../Styles/Buttons'
import { CSSTransition } from 'react-transition-group'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { MyBlendState } from '../../type'
import { SmallProductCell } from './ProductsView'
import { importAll } from './ProductsView'
import { AnalyticsObject } from '../../Analytics/analytics'
import { BackKitIcon } from '../../Styles/Images'
import * as ActionTypes from '../../Constants/ActionsTypes'

const RitualKitView = () => {
    const [inProp, setInProp] = useState<any>(false)
    const { t } = useTranslation()
    const [products, setProducts] = useState<Array<any>>([])
    const [expertProducts, setExpertProducts] = useState<Array<any>>([])
    const [complementariesProducts, setComplementariesProducts] = useState<Array<any>>([])
    const [ritualProducts, setRitualProducts] = useState<Array<any>>([])
    const panier = useSelector((state: MyBlendState) => state.session.allRitualProducts)
    const [warningPopUp, setWarningPopUp] = useState<Array<any>>([])
    const [warningInProp, setWarningInProp] = useState<boolean>(false)
    const ritualProductsFromStore = useSelector((state: MyBlendState) => state.session.ritualKitProducts)
    const dispatch = useDispatch()

    const analyticsObject:AnalyticsObject = useSelector((state:MyBlendState) => state.session.analyticsObject)
    const images = importAll(require.context('../../Assets/Images Produits/', false, /\.(png|jpe?g|svg)$/));

    for (const image in images) {
        const newImage = new Image();
        newImage.src = image;
        window.image = newImage
    }

    useEffect(() => {
        document.getElementById("RITUAL_COMPONENT")?.style.removeProperty("opacity")
        setInProp(true)
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let tab: Array<any> = []
        let key: number = 0
        if (panier[0]) {
            panier[0].forEach((product: any) => {
                //console.log(product)
                if (!product.category.includes("Nutri")) {
                    if (product.category === "superserums"){
                        tab.push(<SmallProductCell ritualProducts={ritualProducts} setRitualProducts={setRitualProducts} isInRitualView={true} product={product} images={images} key={key} />)
                    }
                    else{
                        tab.unshift(<SmallProductCell ritualProducts={ritualProducts} setRitualProducts={setRitualProducts} isInRitualView={true} product={product} images={images} key={key} />)  
                    }
                }
                key++
            });
            setProducts(tab)
        }
        // eslint-disable-next-line
    }, [])

    const showOrHideExpertProducts = () => {
        let tab: Array<any> = []
        let key: number = 0
        if (expertProducts.length === 0) {
            panier[1].forEach((product: any) => {
                tab.push(<SmallProductCell ritualProducts={ritualProducts} setRitualProducts={setRitualProducts} isInRitualView={true} product={product} images={images} key={key} />)
                key++
            });
            setExpertProducts(tab)
        } else {
            setExpertProducts([])
        }
    }

    const showOrHideComplementariesProducts = () => {
        let tab: Array<any> = []
        let key: number = 0
        console.log(panier)
        if (complementariesProducts.length === 0) {
            panier[2].forEach((product: any) => {
                tab.push(<SmallProductCell ritualProducts={ritualProducts} setRitualProducts={setRitualProducts} isInRitualView={true} product={product} images={images} key={key} />)
                key++
            });
            setComplementariesProducts(tab)
        } else {
            setComplementariesProducts([])
        }
    }

    const displayWarningPopUpFunction = () => {
        if (warningPopUp[0]) {
            return warningPopUp[0]
        }
        else {
            return <div></div>
        }
    }

    const displayWarningPopUp = () => {
        if (warningPopUp.length === 0) {
            window.disableScrolling()
            let tab: Array<any> = []
            tab.push(<PopUpWarning key={0} />)
            setWarningPopUp(tab)
            setWarningInProp(true)
        }
    }

    const PopUpWarning = (props: any) => {
        const close = (e: any) => {
            e.stopPropagation()
            setWarningInProp(false)
            window.enableScrolling()
            setTimeout(() => {
                setWarningPopUp([])
            }, 300);
        }
        return (
            <ProductDetailPopUp onClick={(e) => close(e)}>
                <ParagraphText style={{ margin: '40px 24px 20px 24px' }}>
                    {t('ritual.warning1', 'Vous devez sélectionner 3 produits complémentaires, il vous reste #NUMBER# produits à choisir pour valider votre set découverte').replace('#NUMBER#', (6 - ritualProductsFromStore.length).toString())}
                </ParagraphText>
                <MainButton style={{ margin: '0 0 40px 0' }}>
                    {t('ritual.continue', 'Continuer la sélection')}
                </MainButton>
            </ProductDetailPopUp>
        )
    }

    const handleBuy = () => {
        if (ritualProductsFromStore.length === 6) {
                let cartSkus:Array<string> = []

                ritualProductsFromStore.forEach((product:any) => {
                    cartSkus.push(product.sku)
                    //analyticsObject.sendAnalyticsEventForSku(paniers, product.sku, true)
                });
                analyticsObject.sendAnalyticsEvent("add_kit_to_cart", {"skus":cartSkus, "price":50})

                let data = {
                    messageType: "buy_kit",
                    products: cartSkus
                };
                console.log(data)
                window.parent.postMessage(data, "*")

        } else {
            displayWarningPopUp()
        }
    }

    const backStep = () => {
        dispatch({type: ActionTypes.SET_STEP, value: 4})
    }

    return (
        <CSSTransition classNames="swipeToRight" in={inProp} addEndListener={() => null}>
            <ComponentContainer id="RITUAL_COMPONENT" style={{ margin: '0px 0 0 0', opacity: 0 }}>
            <QuestionNavBar/>

                <Retour onClick={() => backStep()} > {t('menu.back', 'Retour')} </Retour>
                <BackKitIcon onClick={() => backStep()} />
                <H0 paddingTop={94}>My Beauty Ritual</H0>
                <H4>
                    {t('ritual.containing', 'VOTRE SET CONTIENT:')}
                </H4>
                <ParagraphText style={{ padding: '20px 24px 0px 24px' }}>
                    {t('ritual.subtitle', 'Votre Essential et vos deux Supersérums + 3 produits de votre choix')}
                </ParagraphText>
                <VolumeAndPrinceText>
                    50,00€
                </VolumeAndPrinceText>

                <ProductsContainer style={{ margin: '24px 24px 84px 24px' }}>
                    <BeigeButton style={{ margin: '30px 0 22px 0' }}>
                        {t('prescription.essentials', 'Mes essentiels')}
                    </BeigeButton>
                    {products}

                    <BeigeButton style={{ margin: '30px 0', textAlign: 'center' }}>
                        {t('prescription.experts', 'Mes experts')}
                    </BeigeButton>
                    <DisplayProductButton style={{ margin: '0 0' }} onClick={() => showOrHideExpertProducts()}>
                        {expertProducts.length === 0 ? '+' : '-'}
                    </DisplayProductButton>
                    {expertProducts}

                    <BeigeButton style={{ margin: '30px 0' }}>
                        {t('prescription.complementary', 'Mes complémentaires')}
                    </BeigeButton>
                    <DisplayProductButton style={{ margin: '0 0' }} onClick={() => showOrHideComplementariesProducts()}>
                        {complementariesProducts.length === 0 ? '+' : '-'}
                    </DisplayProductButton>
                    {complementariesProducts}
                </ProductsContainer>
                <BottomBuyBar>
                    <MainButton style={{ margin: '0 0 0 0' }} onClick={() => handleBuy()}>
                        {t('prescription.buy', 'Acheter')}
                    </MainButton>
                </BottomBuyBar>
                <CSSTransition in={warningInProp} classNames="fadeInUp" addEndListener={() => null}>
                    {displayWarningPopUpFunction()}
                </CSSTransition>
            </ComponentContainer>
        </CSSTransition>)

}

const RitualKitViewComponent = () => {
    return <RitualKitView />
}

export default RitualKitViewComponent