import { ProductDetailPopUp } from '../../Styles/Containers'
import { H2, ParagraphText, ProductName, VolumeAndPrinceText } from '../../Styles/Texts'
import { CloseIcon } from '../../Styles/Images'
import styled from 'styled-components'
import { setGammeTranslation } from './ProductsView'
import { useTranslation } from 'react-i18next'
import { MainButton } from '../../Styles/Buttons'
import { useEffect } from 'react'
import { AnalyticsObject } from '../../Analytics/analytics'
import { useSelector } from 'react-redux'
import { MyBlendState } from '../../type'
import { displayCurrencySymbol } from '../../Constants/Functions'

const ProductDetailView = (props: any) => {
    const { t } = useTranslation()
    const analyticsObject:AnalyticsObject = useSelector((state:MyBlendState) => state.session.analyticsObject)
    const preventDefault = (e: any) => {
        e.preventDefault()
    }
    useEffect(() => {
        document.getElementById("APP")?.addEventListener("touchmove", preventDefault, false)
        document.getElementById("POPOVER_PRODUCT")?.addEventListener("touchmove", function (e) {
            e.stopPropagation()
            e.stopImmediatePropagation()
        }, true)

        return (() => {
            document.getElementById("APP")?.removeEventListener("touchmove", preventDefault)
            document.getElementById("APP")!.style.position = ""
            if(document.getElementById("MAIN_COMPONENT")){
                document.getElementById("MAIN_COMPONENT")!.style.top = ""
            }
        })
    })

    const closePopUp = () => {
        window.enableScrolling()
        props.setInProp(false)
        setTimeout(() => {
            props.setDetailPopUp([])
        }, 400);
    }

    const buyButton = (e:any) => {
        e.stopPropagation()
        let data = {
            messageType: "basket",
            products: props.product.sku
        };

        analyticsObject.sendAnalyticsEvent("add_to_cart", {"sku": props.product.sku, "price": props.product.price[window.market]});
        console.log(data)
        window.parent.postMessage(data, "*")
    }

    const handlePrice = () => {
        if(props.product.price[window.market]){
            return props.product.price[window.market]
        } 

        if(window.market === "uk"){
            return props.product.price["EMEA"]
        }

        return "--"
    }

    var regex = /<[^>]+>/gi
    return (
        <ProductDetailPopUp id={"POPOVER_PRODUCT"} onClick={() => closePopUp()}>
            <CloseIcon />
            <Title>{setGammeTranslation(props.product.category, t)}</Title>
            <img style={{ width: '200px', height: '200px', paddingBottom: '12px' }} alt={props.product.image} src={props.product.image ? props.product.image : null} />
            <ProductName style={{paddingLeft: '24px', paddingRight: '24px'}}>
                {props.product.name[window.lang] ? props.product.name[window.lang] : props.product.name["EnUs"]}
            </ProductName>
            <DescriptionText>
                {(props.product.description[window.lang] ? props.product.description[window.lang] : props.product.description["EnUs"]).replace("<sup>1</sup>", '\u00B9').replace("<sup>2</sup>", '\u00B2').replace(regex, "")}
            </DescriptionText>
            <VolumeAndPriceDetail>
                {props.product.volume[window.unity] ? props.product.volume[window.unity] : props.product.volume["liter"]} - {handlePrice()}{displayCurrencySymbol()}
            </VolumeAndPriceDetail>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 24px 0' }}>
                <MainButton style={{ marginBottom: '20px' }} onClick={(e) => buyButton(e)}>
                    {t('prescription.buy', 'Acheter')}
                </MainButton>
            </div>
        </ProductDetailPopUp>
    )
}

export default ProductDetailView

export const Title = styled(H2)`
    margin-top: 42px;
    margin-bottom: 20px;
    padding-top: 0;
`

export const BuyButtonProduct = styled(MainButton)`
    margin-top: 20px;
    margin-bottom: 40px;
`

export const DescriptionText = styled(ParagraphText)`
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 20px;
    margin: 0;
    white-space: pre-wrap;
`

export const VolumeAndPriceDetail = styled(VolumeAndPrinceText)`
    margin-top: 20px;
    margin-bottom: 0;
`