import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import ViewDispatcher from './Components/ViewDispatcher';
import i18n from './i18nextInit';
import { AppStyled, AppLandscape } from './Styles/Containers'
import { MyBlendState } from './type';
import { GlobalStyle, CoolDown } from './Styles/Texts'
import { useTranslation } from 'react-i18next';
import { AnalyticsObject } from './Analytics/analytics';
import * as ActionType from './Constants/ActionsTypes'
import { loadFaceLandmarksDetectionModel } from './A3Camera/faceLandmarksDetection';

declare global {
  interface Window { lang: string, market: string, unity: string, id: any, textAnim: any, disableScrolling: () => void, enableScrolling: () => void, image: HTMLImageElement }
} 

const App = () => {
  const dispatch = useDispatch()
  const [isLandscape, setLandscape] = useState(false)
  const step = useSelector((state: MyBlendState) => state.session.step)

  window.addEventListener('resize', () => {
    setLandscape(window.innerWidth > window.innerHeight)
  })

  window.history.scrollRestoration = 'manual'
  const stopScrolling = (e: Event) => { e.preventDefault() }

  window.disableScrolling = () => {
    document.getElementById('APP')!.addEventListener('touchmove', stopScrolling, false);
  }

  window.enableScrolling = () => {
    document.getElementById('APP')!.removeEventListener('touchmove', stopScrolling, false);
  }

  const { t } = useTranslation()

  //Handling analytics
  const analyticsObject = new AnalyticsObject()
  dispatch({ type: ActionType.SET_ANALYTIC_OBJECT, value: analyticsObject })

  useEffect(() => {
    setLandscape(window.innerWidth > window.innerHeight)
    let url = new URL(window.location.href)
    let lang = url.searchParams.get("lang")

    loadFaceLandmarksDetectionModel().then((model) => {
      dispatch({ type: ActionType.UPDATE_MODEL, value: model });
    });


    if (lang) {
      i18n.changeLanguage(lang)
    }

    checkLanguage()

    //Handling analytics
    const analyticsObject = new AnalyticsObject()
    dispatch({ type: ActionType.SET_ANALYTIC_OBJECT, value: analyticsObject })

    let market = url.searchParams.get("market")
    if (market === "uk") {
      window.market = "uk"
    }

    console.log("test")
  }, [dispatch])

  const checkLanguage = () => {
    switch (i18n.language) {
      case 'en':
        window.lang = "EnUs"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'fr':
        window.lang = "FrFr"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'ru':
        window.lang = "RuRu"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'es':
        window.lang = "EsEs"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'de':
        window.lang = "DeCh"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'it':
        window.lang = "ItIt"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      case 'pt':
        window.lang = "PtPt"
        window.market = "EMEA"
        window.unity = "liter"
        break;
      default:
        window.lang = "FrFr"
        window.market = "EMEA"
        window.unity = "liter"
        break;
    }
  }

  return (
    <div>
      {!isLandscape ?
        <AppStyled id={'APP'} step={step}>
          <GlobalStyle />

          <ViewDispatcher />
        </AppStyled> :
        <AppStyled id={'APP'} step={step}>
          <GlobalStyle />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", flexDirection: "column", height: "100vh" }}>
            <CoolDown style={{ top: "0px", right: "0px", position: "relative" }}>{t("onboarding.warning", "Veuillez ouvrir mySkinDiag sur votre Mobile afin de profiter d'une meilleure expérience.")}</CoolDown>
            <AppLandscape />
          </div>
        </AppStyled>}
    </div>
  );
}

export default App;
