export const displayCurrencySymbol = (): string => {
    switch (window.market) {
        case "EMEA":
            return "€"
        case "uk":
            return "£"
        default:
            return "$"
    }
}

export const enableFullScreen = (value: boolean) => {
    let message = {
        messageType: "toggle_fullscreen",
        value: value
    }
    console.log("Fullscreen: " + value)
    window.parent.postMessage(message, "*")
}

export const openMBWebsite = (lang: string) => {
    switch (lang) {
        case "fr-FR":
            window.open("https://my-blend.com/fr/home/", "_blank")
            break;
        case "en-UK":
            window.open("https://my-blend.com/en/home/", "_blank")
            break
        default:
            window.open("https://my-blend.com/fr/home/", "_blank")
            break;
    }
}