import { FaceLandmarksDetector, Keypoint, SupportedModels, createDetector } from '@tensorflow-models/face-landmarks-detection';
import '@tensorflow/tfjs-backend-webgl';
import { ConfigDetection, FaceArea, MESH_ANNOTATIONS } from './type';
import FastAverageColor from 'fast-average-color';

export const loadFaceLandmarksDetectionModel = async (): Promise<FaceLandmarksDetector> => {
    return createDetector(SupportedModels.MediaPipeFaceMesh, {
        runtime: 'mediapipe',
        solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
        refineLandmarks: true,
    })

};

export const faceDetection = async (webcam: any, configDetection: ConfigDetection, model?: FaceLandmarksDetector, debug?: boolean): Promise<FaceLandmarksDetectionResults | undefined> => {
    let status: FaceLandmarksDetectionStatus = {
        good: true,
        loading: false,
        modelError: false,
        noFace: false,
        badPositionBottom: false,
        badPositionLeft: false,
        badPositionRight: false,
        badPositionTop: false,
        badDistanceFar: false,
        badDistanceClose: false,
        badOrientation: false,
        badBrightness: false,
    };

    if (!model) {
        return {
            status: {
                loading: true,
                modelError: true,
            },
        };
    }

    if (!webcam) {
        return {
            status: {
                loading: true,
            },
        };
    }
    const video = webcam.video;
    const { videoHeight, videoWidth }: { videoHeight: number, videoWidth: number } = video;

    const predictions = await model.estimateFaces(video);

    
    if (predictions.length === 0) {
        return {
            status: {
                noFace: true,
                good: false,
            },
        };
    }

    const keypoints = predictions[0].keypoints;

    const annotations = Object.entries(MESH_ANNOTATIONS).reduce((acc: any, [key, value]) => {
        acc[key] = value.map((pos: number) => Object.values(keypoints[pos]).slice(0, 3));
        return acc;
    }, {});
    const scalesMesh = Object.values(keypoints).map(k => Object.values(k).slice(0, 3))

    // On recupere les differentes zones du visage pour verifier la lumiere et pour le foundationMatch
    console.warn(videoWidth, videoHeight)
	let faceAreas: FaceArea[] = findFaceAreas(predictions[0].keypoints, video);


	// Si pas en mode debug on verifie la lumiere
	if (!debug) {
		let lValues: number[] = [];
		faceAreas.forEach((faceArea: FaceArea) => {
            console.warn(faceArea)
			let lab = labDetection(faceArea.data?.data.buffer as Buffer);
			lValues.push(lab[0]);
			faceArea.lab = lab;
		});
		let max = Math.max.apply(Math, lValues);
		let min = Math.min.apply(Math, lValues);
		if (max - min > 45) { //45 par drfaut
			status = {
				...status,
				badBrightness: true,
				good: false,
			};
		}
	}
    let res: FaceLandmarksDetectionResults = {
        status: positionDetection(annotations, status, configDetection, videoWidth, videoHeight),
    };

    if (res.status?.good) {
        let image = webcam.getScreenshot();
        res = {
            ...res,
            predictions: [{
                annotations: annotations,
                scaledMesh: scalesMesh,
                faceInViewConfidence: 1
            }],
            image: image,
        };
    }

    return res;
};

const positionDetection = (annotations: any, status: FaceLandmarksDetectionStatus, configDetection: ConfigDetection, imgWidth: number, imgHeight: number): FaceLandmarksDetectionStatus => {
    const heightRatio = imgHeight / (window.innerHeight);

    if (annotations.length === 0) {
        status.noFace = true;
        status.good = false;
    }

    const cheeksDistance = getDistance(annotations.leftCheek[0], annotations.rightCheek[0]);

    if (cheeksDistance < window.outerHeight * 4 / 3 * (configDetection.zMin)) {
        status.badDistanceFar = true;
        status.good = false;
    }

    if (cheeksDistance > window.outerHeight * 4 / 3 * (configDetection.zMax)) {
        status.badDistanceClose = true;
        status.good = false;
    }


    if (annotations.noseBottom[0][0] < imgWidth * (configDetection.xMin)) {
        status.badPositionLeft = true;
        status.good = false;
    }

    if (annotations.noseBottom[0][0] > imgWidth * (configDetection.xMax)) {
        console.warn(imgWidth)
        status.badPositionRight = true;
        status.good = false;
    }

    if ((annotations.noseBottom[0][1] / heightRatio) < (window.innerHeight) * (configDetection.yMin)) {
        status.badPositionTop = true;
        status.good = false;
    }

    if ((annotations.noseBottom[0][1] / heightRatio) > (window.innerHeight) * (configDetection.yMax)) {
        status.badPositionBottom = true;
        status.good = false;
    }

    if (Math.abs(annotations.rightEyeUpper0[4][1] - annotations.leftEyeUpper0[4][1]) > configDetection.roll) {
        status.badOrientation = true;
        status.good = false;
    }

    return status;
};

// Compute the height of the rectangle that represents one area of the face
export const computeHeight = (x1: number, y1: number, x2: number, y2: number) => {
    if (y1 < y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

// Compute the width of the rectangle that represents one area of the face
export const computeWidth = (x1: number, y1: number, x2: number, y2: number) => {
    if (x1 < x2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    } else return -Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getDistance = (a: Array<any>, b: Array<any>): number => {
    return Math.sqrt((b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]));
};

export const setWarningText = (detectionRes: FaceLandmarksDetectionResults): string => {
    if (!detectionRes) {
        return "Loading"
    }
    if (detectionRes.status?.loading) {
        return 'Loading';
    }

    if (detectionRes.status?.good) {
        return 'good';
    }

    if (detectionRes.status?.noFace) {
        return "No Face"
    }

    if (detectionRes.status?.badDistanceFar) {
        return 'Far';
    }
    if (detectionRes.status?.badDistanceClose) {
        return 'Close';
    }
    if (detectionRes.status?.badPositionLeft) {
        return 'Left';
    }
    if (detectionRes.status?.badPositionRight) {
        return 'Right';
    }
    if (detectionRes.status?.badPositionBottom) {
        return 'Low';
    }
    if (detectionRes.status?.badPositionTop) {
        return "High";
    }
    if (detectionRes.status?.badBrightness) {
        return 'Bad light';
    }
    if (detectionRes.status?.badOrientation) {
        return 'Bad orientation';
    }

    else return 'err'
}

export type FaceLandmarksDetectionStatus = {
    loading?: boolean;
    noFace?: boolean;
    modelError?: boolean;
    good?: boolean;
    taken?: boolean;
    badPositionLeft?: boolean;
    badPositionRight?: boolean;
    badPositionTop?: boolean;
    badPositionBottom?: boolean;
    badDistanceFar?: boolean;
    badDistanceClose?: boolean;
    badOrientation?: boolean;
    badBrightness?: boolean;
};

export type FaceLandmarksDetectionResults = {
    status?: FaceLandmarksDetectionStatus;
    predictions?: any;
    image?: any;
};


export const findFaceAreas = (predictions: Keypoint[], video: any): FaceArea[] => {
    const {videoWidth, videoHeight} = video;
	let canvas = document.createElement('canvas');
	canvas.width = videoWidth;
	canvas.height = videoHeight;
	let ctx = canvas.getContext('2d');
	let faceAreas: FaceArea[] = [];
	if (ctx) {
		ctx.drawImage(video, 0, 0, videoWidth, videoHeight); 
		Object.keys(FACE_AREAS).forEach((faceArea: string) => {
			const x = Object.values(predictions[FACE_AREAS[faceArea].origin])[0];           
			const y = Object.values(predictions[FACE_AREAS[faceArea].origin])[1];
			const x2 = Object.values(predictions[FACE_AREAS[faceArea].abs])[0];
			const y2 = Object.values(predictions[FACE_AREAS[faceArea].abs])[1];
			const x3 = Object.values(predictions[FACE_AREAS[faceArea].ord])[0];
			const y3 = Object.values(predictions[FACE_AREAS[faceArea].ord])[1];

			const width = computeWidth(x, y, x2, y2);
			const height = computeHeight(x, y, x3, y3);
            const data = ctx?.getImageData(x, y, width, height);
            
			faceAreas.push({
				name: faceArea,
				x: x,
				y: y,
				width: width,
				height: height,
				data: data,
			});
		});
	}
	return faceAreas;
};

export const FACE_AREAS: any = {
	leftForehead: {
		origin: 299,
		abs: 333,
		ord: 297,
	},
	middleForehead: {
		origin: 109,
		abs: 338,
		ord: 108,
	},
	rightForehead: {
		origin: 69,
		abs: 104,
		ord: 67,
	},
	nose: {
		origin: 45,
		abs: 275,
		ord: 8,
	},
	leftCheek: {
		origin: 422,
		abs: 364,
		ord: 330,
	},
	rightCheek: {
		origin: 202,
		abs: 135,
		ord: 119,
	},
	chin: {
		origin: 194,
		abs: 418,
		ord: 176,
	},
};

// Convert rgb color value to lab
const rgb2lab = (rgb: number[]) => {
	var r = rgb[0] / 255,
		g = rgb[1] / 255,
		b = rgb[2] / 255,
		x,
		y,
		z;

	r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
	g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
	b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

	x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
	y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
	z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

	x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
	y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
	z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

	return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
};

export const labDetection = (buffer: Buffer): number[] => {
	const arr = new Uint8Array(buffer);
	const fac = new FastAverageColor();
	let rgb = fac.getColorFromArray4(arr);
    console.warn(buffer)
	let lab = rgb2lab(rgb);
	return lab;
};