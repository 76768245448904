import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import * as ActionsTypes from '../Constants/ActionsTypes';


import OTSTCLogo from '../Assets/Icons/img_by_creative_biometric.png'

import { FaceLandmarksDetectionResults, faceDetection } from '../A3Camera/faceLandmarksDetection';
import { ConfigDetection } from '../A3Camera/type';
import skinDiagDispatcher from './SkinDiagApi';
import { MyBlendState } from '../type';
import { CameraViewComponent, Flash, Mask, WarningContainer, WarningIconContainer } from '../Styles/Containers';
import { TapButtonToSwitch } from '../Styles/Buttons';
import { CameraText, WarningText, WarningTextInBar } from '../Styles/Texts';
import { DistanceArrowIcon, DistanceIcon, LightWarnIcon, OtstcLogo, PositionArrowIcon, PositionIcon } from '../Styles/Images';
import { AnalyticsObject } from '../Analytics/analytics';
//import skinDiagDispatcher from './SkinDiagApi'
//import fakeImg from '../Assets/Images Produits/fakeBigImg.png' A mettre dans le dossier en cas de nécessité, trop grosse.

const defaultConfigDetection: ConfigDetection = {
    xMin: 0.45,
    xMax: 0.7,
    yMin: 0.4, // 0.5
    yMax: 0.8, // 0.65
    zMin: 0.35,
    zMax: 0.7,
    roll: 30,
};

export const NAVSIZE: number = 60;

const NewCameraView = () => {
    const webcamRef = useRef(null);
    const analyticsObject: AnalyticsObject = useSelector((state: MyBlendState) => state.session.analyticsObject)
    const [oval, setOval] = useState<any>([]);
    const { model } = useSelector((state: MyBlendState) => state.session);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [inProp, setInProp] = useState<boolean>(false);
    const timer = useRef<number>(0);
    const intervalRef = useRef<any>(null);
    const [isWebcamReady, setIsWebcamReady] = useState<boolean>(false);
    //eslint-disable-next-line
    const [mirrored, setMirrored] = useState<boolean>(true)
    const [facingMode, setFacingMode] = useState<string>("user")
    //eslint-disable-next-line
    const [warningText, setWarningText] = useState<string | null>(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
    const [waitingCounter, setWaitingCounter] = useState<number>(0);
    const isPhotoOk = useRef<boolean>(false);
    const storeErrors = useSelector((state: MyBlendState) => state.session.error)

    let tapTwiceSwitchCamera: boolean

    const [detectionRes, setDetectionRes] = useState<FaceLandmarksDetectionResults>({
        status: {
            good: false,
            loading: true,
            modelError: false,
            noFace: false,
            badPositionBottom: false,
            badPositionLeft: false,
            badPositionRight: false,
            badPositionTop: false,
            badDistanceFar: false,
            badDistanceClose: false,
            badOrientation: false,
            badBrightness: false,
        },
    });

    const resizeObserver = new ResizeObserver((entries: any) => {
        for (let entry of entries) {
            // ... entry has the full info about your element dimensions
            console.log(entry);
        }
    });

    let isTablet = false;

    const videoConstaints = {
        facingMode: 'user',
        height: { ideal: 1500, max: 1500 },
        width: { ideal: 2000, max: 2000 },
    };

    useEffect(() => {
        setInProp(true);
        //reset l'experience
        analyticsObject.sendAnalyticsEvent("camera_begin", "");
        dispatch({ type: ActionsTypes.RESET_EXPERIENCE });
        timer.current = Date.now();

        //window.disableScrolling()
        //eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        document.body.style.backgroundColor = "#F4F3F0"
        if (document.getElementById('CAMERA')) {
            resizeObserver.observe(document.getElementById('CAMERA')!);
        }

        return () => {
            // clean
            //resizeObserver.unobserve(document.getElementById('CAMERA')!);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const back = () => {
            window.clearInterval(window.id);
            window.clearInterval(window.textAnim);
        };

        let data = {
            messageType: 'toggle_fullscreen',
            value: true,
        };

        console.log('[CAMERA] Full screen !');
        window.parent.postMessage(data, '*');

        return () => {
            back();
        };
        //eslint-disable-next-line
    }, []);

    // Cas ou il y a une erreur.
    useEffect(() => {
        if (storeErrors.length > 0) {
            analyticsObject.sendAnalyticsEvent("camera_failed", "");
            setTimeout(() => {
                dispatch({ type: ActionsTypes.ANALYSE_ERROR, value: [] })
            }, 3000);
        }

        //eslint-disable-next-line
    }, [storeErrors])

    useEffect(() => {
        if (!model || !isWebcamReady || !webcamRef.current || isPhotoOk.current) {
            return;
        }

        intervalRef.current = setInterval(() => {
            faceDetection(webcamRef.current, defaultConfigDetection, model, false).then((res: any) => {
                setDetectionRes(res);
            });
        }, 500);

        return () => {
            clearInterval(intervalRef.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWebcamReady, model, webcamRef, isPhotoOk.current]);

    useEffect(() => {
        console.warn(detectionRes.status)
        if (detectionRes.status?.modelError) {
            setWarningText(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.loading) {
            setWarningText(t('cameraView.pleaseWait', 'Veuillez patienter') + '...');
            setWaitingCounter(0);
            return;
        }

        if (detectionRes.status?.good) {
            setWarningText(null);
            setWaitingCounter((prev) => prev + 1);
            return;
        }

        if (detectionRes.status?.noFace) {
            setWarningText(t('cameraView.too_left', 'Place your face '));
            setWaitingCounter(0);
            return;
        }

        if (detectionRes.status?.badDistanceFar) {
            setWarningText(t('cameraView.far', 'Vous êtes trop près.'));
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.badDistanceClose) {
            setWarningText(t('cameraView.close', 'Plus loin'));
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.badPositionLeft) {
            setWarningText(t('cameraView.too_left', 'Place your face '));
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.badPositionRight) {
            setWarningText(t('cameraView.too_right', 'Place your face in the oval'));
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.badPositionBottom) {
            setWarningText(t('cameraView.too_down', 'Place your face in the oval.'));
            setWaitingCounter(0);
            return;
        }
        if (detectionRes.status?.badPositionTop) {
            setWarningText(t('cameraView.too_high', 'Place your face in the oval.'));
            setWaitingCounter(0);
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detectionRes.status]);

    useEffect(() => {
        if (isPhotoOk.current) return;
        if (waitingCounter >= 5) {
            if (detectionRes.image) {
                isPhotoOk.current = true;
                // onPictureTaken(detectionRes.image);
                document.getElementById('FLASH')!.style.opacity = "1";
                setTimeout(() => {
                    fetch(detectionRes.image)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const action = { type: ActionsTypes.ANALYSE_PHOTO, value: URL.createObjectURL(blob) };
                            setInProp(false);
                            dispatch(action);
                            dispatch(skinDiagDispatcher);
                            // downloadImagesForOTSTC(blob)
                        });
                }, 200);

            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingCounter]);

    const handleErrorCamera = () => {
        dispatch({ type: ActionsTypes.SET_STEP, value: 0 });
    };


    if (window.innerWidth / window.innerHeight > 3 / 4) {
        isTablet = true;
    }

    const tapToSwitchCamera = () => {
        if (!tapTwiceSwitchCamera) {
            tapTwiceSwitchCamera = true;
            setTimeout(function () { tapTwiceSwitchCamera = false; }, 300);
            return false;
        }
        window.clearInterval(window.id)
        setTimeout(() => {
            if (facingMode === "user") {
                setFacingMode("environment")
                setMirrored(false)
            } else {
                setFacingMode("user")
                setMirrored(true)
            }
        }, 500);

        document.getElementById("SWITCH_BUTTON")!.style.pointerEvents = 'none'

        setTimeout(function () {
            document.getElementById("SWITCH_BUTTON")!.style.pointerEvents = 'all'
        }, 2000)
    }

    useEffect(() => {
        setInProp(true)
        //reset l'experience
        dispatch({ type: ActionsTypes.RESET_EXPERIENCE })
        let ovalHeight: number
        let ovalWidth: number
        let factor: number
        factor = window.outerHeight / window.outerWidth > 2 ? 2.5 : 2
        ovalHeight = window.outerHeight * 4 / 8
        ovalWidth = window.outerHeight * 0.34

        document.getElementById('ARROW_POSITION_CONTAINER')!.style.top = (window.outerHeight - ovalHeight) / factor + 32 + 'px'
        document.getElementById('ARROW_DISTANCE_CONTAINER')!.style.top = (window.outerHeight - ovalHeight) / factor + 32 + 'px'

        const Oval = styled.div`
        position: absolute;
        display: block;
        top: ${(window.outerHeight - ovalHeight) / factor}px;
        height: ${ovalHeight}px;
        width: ${ovalWidth}px;
        Z-index: 3;
        border-radius: ${ovalWidth}px/${ovalHeight}px;
        background: black;
        border-width: 8px;
        border-style: solid;
        mix-blend-mode: overlay;
        `;

        const Line = styled.div`
        position: absolute;
        border: 2px dashed white;
        border-top: none;
        width: ${ovalWidth}px;
        opacity: 0.6;
        top: ${(window.outerHeight / 2) - (factor === 2.5 ? 64 : 30)}px;
        `;

        let tab = []
        tab.push(<Oval key={0} id={'OVAL'}>
        </Oval>)
        tab.push(<Line key={1} id={'LINE'} />)
        setOval(tab)

    }, [dispatch])

    const handleGoodDistance = (): boolean => {
        if (detectionRes.status?.noFace) {
            return false
        }

        return !detectionRes.status?.badDistanceClose && !detectionRes.status?.badDistanceFar
    }

    const handleGoodBrightness = (): boolean => {
        if (detectionRes.status?.noFace) {
            return false
        }

        return !detectionRes.status?.badBrightness
    }

    const handleGoodPosition = (): boolean => {
        if (detectionRes.status?.noFace) {
            return false
        }

        if (detectionRes.status?.badOrientation) {
            return false
        }

        if (detectionRes.status?.badPositionBottom
            || detectionRes.status?.badPositionLeft
            || detectionRes.status?.badPositionRight
            || detectionRes.status?.badPositionTop) {
            return false
        }
        return true
    }

    const handleDistanceText = () => {
        if (detectionRes.status?.badDistanceFar) {
            return t('camera.closer', 'Plus proche')
        }
        else {
            return t('camera.farther', 'Plus loin')
        }
    }

    const handlePositionText = () => {
        if (detectionRes.status?.badPositionBottom) {
            return t('camera.lower', 'Plus bas')

        }

        if (detectionRes.status?.badPositionLeft) {
            return t('camera.moreRight', 'Plus à droite')
        }

        if (detectionRes.status?.badPositionRight) {
            return t('camera.moreLeft', 'Plus à gauche')
        }

        if (detectionRes.status?.badPositionTop) {
            return t('camera.upper', 'Plus haut')
        }

    }

    return (
        <CSSTransition in={inProp} key={1} classNames='swipeToRight' addEndListener={() => null}>
            <CameraViewComponent id={'CAMERA'}>
                {detectionRes.status?.loading && storeErrors.length === 0 && <CameraText id={'CAM_TEXT'}>{t("camera.wait", "Please wait") + '...'}</CameraText>}
                {storeErrors.length > 0 && <CameraText id={'CAM_TEXT'}>{t("cameraView." + storeErrors[0])}</CameraText>}

                {!detectionRes.status?.loading && storeErrors.length === 0 && <WarningContainer>
                    <div id="DISTANCE_CONTAINER" style={{ flexDirection: 'column', alignItems: 'center', display: "flex" }}>
                        <DistanceIcon $isGood={handleGoodDistance()} id="DISTANCE_ICON" />
                        <WarningTextInBar $isGood={handleGoodDistance()} id="DISTANCE_TEXT">
                            {t('camera.distance', 'Distance')}
                        </WarningTextInBar>
                    </div>

                    <div id="ORIENTATION_CONTAINER" style={{ flexDirection: 'column', alignItems: 'center', display: "flex" }}>
                        <LightWarnIcon $isGood={handleGoodBrightness()} id="ORIENTATION_ICON" />
                        <WarningTextInBar $isGood={handleGoodBrightness()} id="ORIENTATION_TEXT">
                            {t('camera.brightness', 'Brightness')}
                        </WarningTextInBar>
                    </div>

                    <div id="POSITION_CONTAINER" style={{ flexDirection: 'column', alignItems: 'center', display: "flex" }}>
                        <PositionIcon $isGood={handleGoodPosition()} id="POSITION_ICON" />
                        <WarningTextInBar $isGood={handleGoodPosition()} id="POSITION_TEXT">
                            {t('camera.position', 'Position')}
                        </WarningTextInBar>
                    </div>
                </WarningContainer>}

                <WarningIconContainer $isDisplayed={detectionRes.status?.badPositionBottom! || detectionRes.status?.badPositionLeft! || detectionRes.status?.badPositionRight! || detectionRes.status?.badPositionTop!} id={"ARROW_POSITION_CONTAINER"} style={{ paddingLeft: '72px' }}>
                    <PositionArrowIcon $isLeft={detectionRes.status?.badPositionLeft!} $isTop={detectionRes.status?.badPositionTop!} $isRight={detectionRes.status?.badPositionRight!} $isBottom={detectionRes.status?.badPositionBottom!} id={"ARROW_POSITION_ICON"} />
                    <WarningText id={"ARROW_POSITION_TEXT"}>
                        {handlePositionText()}
                    </WarningText>
                </WarningIconContainer>

                <WarningIconContainer $isDisplayed={detectionRes.status?.badDistanceFar! || detectionRes.status?.badDistanceClose!} id={"ARROW_DISTANCE_CONTAINER"} style={{ paddingRight: '72px' }}>
                    <DistanceArrowIcon $isFar={detectionRes.status?.badDistanceFar!} id={"ARROW_DISTANCE_ICON"} />
                    <WarningText id={"ARROW_DISTANCE_TEXT"}>
                        {handleDistanceText()}
                    </WarningText>
                </WarningIconContainer>

                <CameraText style={{ opacity: 0 }} id={'CAMTEXT'}>{t("camera.pleaseWait", "Veuillez patienter") + '...'}</CameraText>
                {/* <p>Please allow camera to use MyBlend</p> */}
                <Webcam
                    id='CAMERA'
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstaints}
                    onUserMedia={() => setIsWebcamReady(true)}
                    onUserMediaError={handleErrorCamera}
                    height={window.innerHeight} // -NAVSIZE
                    width={(window.outerHeight * 4) / 3}
                    //style={{ position: 'fixed', height: window.innerHeight - NAVSIZE, width: window.outerHeight * 3 / 4, top: 0, left: (window.innerWidth - window.outerHeight * 3 / 4) / 2 }}
                    mirrored={true}
                    screenshotFormat='image/jpeg'
                    autoPlay={true}
                    screenshotQuality={0.96}
                    forceScreenshotSourceSize={true}
                    imageSmoothing={false}
                    playsInline
                    muted
                    style={{
                        transition: 'all 0.4s',
                        position: 'absolute',
                        height: isTablet ? ((window.outerHeight - 90) * 4) / 3 : window.innerHeight, // -Navsize
                        width: isTablet ? window.outerWidth : (window.outerHeight * 3) / 4,
                        left: isTablet ? 0 : (window.innerWidth - (window.outerHeight * 3) / 4) / 2,
                        bottom: isTablet ? '' : 0,
                    }}
                />

                <Mask />
                <Flash id="FLASH" />
                {oval}
                <TapButtonToSwitch onClick={() => tapToSwitchCamera()} id={"SWITCH_BUTTON"} />
                <OtstcLogo src={OTSTCLogo} alt="By OTSTC" />

            </CameraViewComponent>
        </CSSTransition>
    );
};

const NewCameraComponent = () => {
    return <NewCameraView />;
};

export default NewCameraComponent;
