import styled from 'styled-components'
import { ParagraphText } from '../Styles/Texts'
import { ComponentContainer } from '../Styles/Containers'
import { IMG } from '../Styles/Images'
import { MyBlendState } from '../type';
import Logo from '../Assets/LOGOMyBlendSquare.svg'
import { useTranslation } from 'react-i18next'
import { BackQuestIcon } from '../Styles/Images'
import { useDispatch, useSelector } from 'react-redux'
import * as Actiontypes from '../Constants/ActionsTypes';
import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group'
import { useState } from 'react'

const WaitingText = styled(ParagraphText)`
    position: relative;
    margin-top: 200px;
    margin-left: 64px;
    margin-right: 64px;
`;

export const MyBlendLogo = styled(IMG)`
    position: relative;
    background-image : url(${Logo});
    margin-top: 80px;
    height: 40px;
    width: 55px;
`;

const WaitingView = () => {
    const questions = useSelector((state: MyBlendState) => state.session.questions)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const analyses = useSelector((state: MyBlendState) => state.session.analyses)
    const [inProp, setInProp] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        setInProp(true)
        if (analyses.length > 1 && questions.length > 3) {
            setTimeout(() => {
                setInProp(false)
                dispatch({ type: Actiontypes.SET_STEP, value: 4 })
            }, 2000);
        }
        //eslint-disable-next-line
    }, [analyses])

    return (
        <CSSTransition in={inProp} classNames="swipeToRight" addEndListener={() => null}>
            <ComponentContainer>
                {questions.length > 0 && <BackQuestIcon step={1} onClick={() => dispatch({ type: Actiontypes.SET_STEP, value: 3 })} />}
                <WaitingText>
                    {questions.length > 0 ?
                        t('waiting.2.title', 'Merci, découvrons ensemble les résultats de votre diagnostic personnalisé et votre prescription de produits experts.')
                        : t('waiting.1.title', 'Nous analysons votre visage et votre peau, passons désormais à votre style de vie.')}
                </WaitingText>
                <LogoAsSvg />
            </ComponentContainer>
        </CSSTransition>
    )
}

const LogoAsSvg = () => {
    return <svg style={{margin: '24px 0 0 0'}} className="blinking" xmlns="http://www.w3.org/2000/svg" width="55.451" height="38.585" viewBox="0 0 55.451 38.585">
        <path className="a" d="M1201.756,805.53h-18.2V767.27h17.764c7.817,0,12.626,3.881,12.626,9.893v.108a8.9,8.9,0,0,1-5.029,8.254c4.429,1.693,7.161,4.264,7.161,9.4v.11C1216.076,802.033,1210.392,805.53,1201.756,805.53Zm3.827-26.945c0-2.515-1.968-3.936-5.521-3.936h-8.309v8.088h7.761c3.718,0,6.069-1.2,6.069-4.044Zm2.131,15.3c0-2.568-1.914-4.154-6.231-4.154h-9.729v8.417h10c3.716,0,5.958-1.311,5.958-4.153Z" transform="translate(-1160.625 -766.945)" />
        <path className="a" d="M1187.843,778.761H1179.7c-1.959,0-2.967.871-2.967,2.425s1.007,2.506,2.967,2.506h8.142v4.137H1179.7c-1.959,0-2.967.874-2.967,2.426s1.007,2.505,2.967,2.505h8.142V796.9h-14.6v-4.137h2.071a5.216,5.216,0,0,1-2.341-4.3,4.366,4.366,0,0,1,2.314-4.111,6.008,6.008,0,0,1-2.314-4.792c0-3.077,1.85-4.928,5.364-4.928h9.5Z" transform="translate(-1172.976 -758.361)" />
        <path className="a" d="M1173.1,771.424l9.939,3.186-9.939,3.322v4.382l14.652-5.717a1.382,1.382,0,0,1,.953,1.444c0,.039,0,.08-.006.121a4.714,4.714,0,0,1-1,2.2l2.854,1.785a5.144,5.144,0,0,0,.684-1.014,7.367,7.367,0,0,0,.866-2.874l.013-.089h-.005a5.835,5.835,0,0,0,.022-.65c-.089-2.4-1.116-3.675-4.085-4.792L1173.1,767.12Z" transform="translate(-1172.829 -767.12)" />
    </svg>
}

const WaitingViewComponent = () => {
    return <WaitingView />
}

export default WaitingViewComponent