import { TFunction } from "i18next"
import ICONDisqueCoton from '../Assets/Icons/ICONDisqueCoton.svg'
import ICONNettoyant from '../Assets/Icons/ICONNettoyant.svg'
import ICONLotion from '../Assets/Icons/ICONLotion.svg'
import ICONSerum from '../Assets/Icons/ICONSerum.svg'
import ICONAccessoire from '../Assets/Icons/ICONAccessoire.svg'
import ICONOeil from '../Assets/Icons/ICONOeil.svg'
import ICONMasque from '../Assets/Icons/ICONMasque.svg'
import ICONCreme from '../Assets/Icons/ICONCreme.svg'
import ICONHydratation from '../Assets/Icons/ICONHydratation.svg'
import ICONAlcool from '../Assets/Icons/ICONAlcool2.svg'
import ICONAucun from '../Assets/Icons/ICONAucun.svg'
import ICONVille from '../Assets/Icons/ICONVille.svg'
import ICONVoyage from '../Assets/Icons/ICONVoyage.svg'
import ICONTabac from '../Assets/Icons/ICONTabac.svg'
import ICONSoleil from '../Assets/Icons/ICONSoleil.svg'
import ICONCafe from '../Assets/Icons/ICONCafe.svg'
import ICONClimatisation from '../Assets/Icons/ICONClimatisation.svg'
import ICONFroid from '../Assets/Icons/ICONFroid.svg'
import ICONGras from '../Assets/Icons/ICONGras.svg'
import ICONSucre from '../Assets/Icons/ICONSucre.svg'

export enum Quest {
    gender = 0,
    age,
    skinFeel,
    routine,
    cleanser_Water,
    concerns,
    sensitivity,
    environment,
    today_health,
    sleep,
    weakness,
    done
}

export enum Answer {
    man = "man",
    woman = "woman",
    noGender = "noSex",

    age2030 = "age2030",
    age3040 = "age3040",
    age4050 = "age4050",
    age50Plus = "age50plus",

    shinyAllFace = "shinyAllFace",
    noProblem = "noProblem",
    sensible = "sensible",
    inconfortable = "inconfortable",
    shinyTZone = "shinyTZone",

    makeupremover = "makeupremover",
    cleanser = "cleanser",
    lotion = "lotion",
    serum = "serum",
    cream = "cream",
    eyes = "eyes",
    mask = "mask",
    accesssories = "accessories",

    // cleanserWater
    rinseWithWater = "rinseWithWater",
    rinseWithoutWater = "rinseWithoutWater",

    // concern
    radiance = "radiance",
    hydration = "hydration",
    wrinkles = "wrinkles",
    drySkin = "drySkin",

    //case redness // already exist
    pores = "pores",
    imperfections = "imperfections",
    redness = "redness",
    darkspot = "darkspots",// tache
    firmness = "firmness",
    spots = "spots",

    // sensitivity
    // case cream already exist
    textureCream = "textureCream",
    textureFluid = "textureFluid",
    richCream = "richCream",

    // environnement
    city = "city",
    sun = "sun",
    trip = "trip",
    climatisation = "climatisation",
    lowTemperature = "lowTemperature",

    // todayHealth
    stressed = "stressed",
    tired = "tired",
    healty = "healthy",

    // sleep
    insufisant = "insuffisant",
    mid = "mid",
    good = "good",

    // weakness
    coffee = "coffee",
    alcohol = "alcohol",
    sugar = "sugar",
    fat = "fat",
    tabac = "tabac",
    none = "none",

    noAnswer = "noAnswer",
    problem = 'Il y a un problem'
}

export const setQuest = (quest: Quest, t: TFunction): string => {
    switch (quest) {
        case Quest.gender:
            return t('question.gender', 'Etes-vous ?')
        case Quest.age:
            return t('question.age', 'Dans quelle tranche d\'âge vous situez vous ?')
        case Quest.skinFeel:
            return t('question.skin', 'Comment décrivez-vous votre peau au quotidien ?')
        case Quest.routine:
            return t('question.routine', 'Quels produits intégrez-vous à votre routine de soin ?')
        case Quest.cleanser_Water:
            return t('question.cleaning', 'Quel type de nettoyage préférez-vous ?')
        case Quest.concerns:
            return t('question.concerns', 'Quelles sont vos 3 préoccupations prioritaires par ordre d’importance ?')
        case Quest.sensitivity:
            return t('question.texture', 'Quelle texture préfèrez-vous ?')
        case Quest.environment:
            return t('question.environment', 'Dans quel environnement évoluez-vous ?')
        case Quest.today_health:
            return t('question.feel', 'Comment vous sentez-vous aujourd’hui ?')
        case Quest.sleep:
            return t('question.sleep', 'En ce moment, comment évaluez-vous la qualité de votre sommeil ?')
        case Quest.weakness:
            return t('question.weakness', 'Quelles sont vos habitudes au quotidien ?')
        default:
            return 'Il y a un probleme'
    }
}
export const setAnswers = (quest: Quest): Array<Answer> => {
    switch (quest) {
        case Quest.gender:
            return [Answer.woman, Answer.man, Answer.noGender]
        case Quest.age:
            return [Answer.age2030, Answer.age3040, Answer.age4050, Answer.age50Plus]
        case Quest.skinFeel:
            return [Answer.shinyTZone, Answer.shinyAllFace, Answer.sensible, Answer.inconfortable, Answer.noProblem]
        case Quest.routine:
            return [Answer.makeupremover, Answer.cleanser, Answer.lotion, Answer.serum, Answer.cream, Answer.eyes, Answer.mask, Answer.accesssories]
        case Quest.cleanser_Water:
            return [Answer.rinseWithWater, Answer.rinseWithoutWater]
        case Quest.concerns:
            return [Answer.radiance, Answer.hydration, Answer.wrinkles, Answer.firmness, Answer.spots, Answer.redness, Answer.pores, Answer.imperfections, Answer.drySkin]
        case Quest.sensitivity:
            return [Answer.textureCream, Answer.textureFluid]
        case Quest.environment:
            return [Answer.city, Answer.sun, Answer.lowTemperature, Answer.trip, Answer.climatisation, Answer.noAnswer]
        case Quest.today_health:
            return [Answer.stressed, Answer.tired, Answer.healty]
        case Quest.sleep:
            return [Answer.insufisant, Answer.mid, Answer.good]
        case Quest.weakness:
            return [Answer.coffee, Answer.alcohol, Answer.sugar, Answer.fat, Answer.tabac, Answer.none]
        default:
            return [Answer.problem]
    }
}

export const setAnswerTranslation = (answer: Answer, t: TFunction) => {
    switch (answer) {
        case Answer.man:
            return t('answer.man', 'Un homme')
        case Answer.woman:
            return t('answer.woman', 'Une femme')
        case Answer.noGender:
            return t('answer.other', 'Je ne souhaite pas me définir')
        case Answer.age2030:
            return t('answer.age2030', 'Entre 20 et 30 ans')
        case Answer.age3040:
            return t('answer.age3040', 'Entre 30 et 40 ans')
        case Answer.age4050:
            return t('answer.age4050', 'Entre 40 et 50 ans')
        case Answer.age50Plus:
            return t('answer.age50plus', 'Plus de 50 ans')
        case Answer.shinyTZone:
            return t('answer.shinyTZone', 'Brillante sur la zone T')
        case Answer.shinyAllFace:
            return t('answer.shinyAllFace', 'Brillante sur l\'intégralité du visage')
        case Answer.sensible:
            return t('answer.sensible', 'Sensible (avec des rougeurs)')
        case Answer.inconfortable:
            return t('answer.inconfortable', 'Inconfortable')
        case Answer.noProblem:
            return t('answer.noProblem', 'Sans problèmes majeurs')
        case Answer.makeupremover:
            return t('answer.makeupremover', 'Démaquillant')
        case Answer.cleanser:
            return t('answer.cleanser', 'Nettoyant')
        case Answer.lotion:
            return t('answer.lotion', 'Lotion')
        case Answer.serum:
            return t('answer.serum', 'Sérum')
        case Answer.cream:
            return t('answer.cream', 'Crème hydratante')
        case Answer.mask:
            return t('answer.mask', 'Masque')
        case Answer.eyes:
            return t('answer.eyes', 'Contours des yeux')
        case Answer.accesssories:
            return t('answer.accessories', 'Accessoires')
        case Answer.rinseWithWater:
            return t('answer.rinseWithWater', 'Nettoyage à rincer')
        case Answer.rinseWithoutWater:
            return t('answer.rinseWithoutWater', 'Nettoyage sans rinçage')
        case Answer.radiance:
            return t('answer.radiance', 'Manque d\'éclat')
        case Answer.hydration:
            return t('answer.hydration', 'Manque d\'hydratation')
        case Answer.wrinkles:
            return t('answer.wrinkles', 'Rides')
        case Answer.firmness:
            return t('answer.firmness', 'Manque de fermeté')
        case Answer.spots:
            return t('answer.darkspots', 'Tâches')
        case Answer.redness:
            return t('answer.redness', 'Rougeurs')
        case Answer.drySkin:
            return t("answer.drySkin", "Peau sèche et fragilisées")
        case Answer.imperfections:
            return t('answer.spots', 'Imperfections')
        case Answer.pores:
            return t('answer.pores', 'Pores dilatés')
        case Answer.textureCream:
            return t('answer.textureCream', 'Crème')
        case Answer.textureFluid:
            return t('answer.textureFluid', 'Fluide')
        case Answer.richCream:
            return t("answer.richCream", "Crème riche")
        case Answer.city:
            return t('answer.city', 'Pollution urbaine')
        case Answer.sun:
            return t('answer.sun', 'Exposition au soleil')
        case Answer.lowTemperature:
            return t('answer.lowTemperature', 'Exposition au froid')
        case Answer.trip:
            return t('answer.trip', 'Voyages fréquents')
        case Answer.climatisation:
            return t('answer.climatisation', 'Climatisation')
        case Answer.noAnswer:
            return t('answer.noneEnv', 'Sans réponse')
        case Answer.stressed:
            return t('answer.stressed', 'Stressé(e)')
        case Answer.healty:
            return t('answer.healthy', 'En forme')
        case Answer.tired:
            return t('answer.tired', 'Fatigué(e)')
        case Answer.insufisant:
            return t('answer.insufficient', 'Insuffisante')
        case Answer.mid:
            return t('answer.mid', 'Normale')
        case Answer.good:
            return t('answer.good', 'Satisfaisante')
        case Answer.coffee:
            return t('answer.coffee', 'Café')
        case Answer.alcohol:
            return t('answer.alcohol', 'Alcool')
        case Answer.sugar:
            return t('answer.sugar', 'Sucre')
        case Answer.fat:
            return t('answer.fat', 'Gras')
        case Answer.tabac:
            return t('answer.tabac', 'Tabac')
        case Answer.none:
            return t('answer.noneWeak', 'Aucune')
        default:
            return "problem"
    }
}

export const setIcon = (answer: Answer) => {
    switch (answer) {
        case Answer.makeupremover:
            return ICONDisqueCoton
        case Answer.cleanser:
            return ICONNettoyant
        case Answer.lotion:
            return ICONLotion
        case Answer.serum:
            return ICONSerum
        case Answer.cream:
            return ICONCreme
        case Answer.eyes:
            return ICONOeil
        case Answer.mask:
            return ICONMasque
        case Answer.accesssories:
            return ICONAccessoire
        case Answer.rinseWithWater:
            return ICONHydratation
        case Answer.rinseWithoutWater:
            return ICONDisqueCoton
        case Answer.textureCream:
            return ICONCreme
        case Answer.textureFluid:
            return ICONLotion
        case Answer.city:
            return ICONVille
        case Answer.sun:
            return ICONSoleil
        case Answer.climatisation:
            return ICONClimatisation
        case Answer.lowTemperature:
            return ICONFroid
        case Answer.trip:
            return ICONVoyage
        case Answer.noAnswer:
            return ICONAucun
        case Answer.coffee:
            return ICONCafe
        case Answer.tabac:
            return ICONTabac
        case Answer.fat:
            return ICONGras
        case Answer.none:
            return ICONAucun
        case Answer.sugar:
            return ICONSucre
        case Answer.alcohol:
            return ICONAlcool
        default:
            return null
    }
}

export const setSelectionnable = (quest: Quest) => {
    //window.enableScrolling()
    switch (quest) {
        case Quest.routine:
            return true
        case Quest.environment:
            return true
        case Quest.weakness:
            return true
        default:
            return false
    }
}

export const setOrder = (quest: Quest) => {
    //window.enableScrolling()
    switch (quest) {
        case Quest.concerns:
            return true
        default:
            return false
    }
}

export const setNoneMode = (answer: Answer) => {

}
