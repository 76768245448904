import styled from 'styled-components'
import ConcernView from './ConcernView'
import ProductsView from "./ProductsView"
import FooterView from './FooterView'
import { Recommencer} from '../../Styles/Texts'
import { useTranslation } from 'react-i18next'

import { CSSTransition } from 'react-transition-group'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { BackHomeIcon } from '../../Styles/Images'
import * as ActionTypes from '../../Constants/ActionsTypes'
import { enableFullScreen } from '../../Constants/Functions'
export const MainViewContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`
const MainResultView = () => {
    const [inProp, setInProp] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        document.getElementById("MAIN_COMPONENT")?.style.removeProperty("opacity")
        setInProp(true)
        enableFullScreen(false)
    }, [])

    const backStep = () => {
        dispatch({type: ActionTypes.SET_STEP, value: 0})
    }

    return (
        <CSSTransition addEndListener={() => null} classNames="swipeToRightResults" in={inProp}>
            <MainViewContainer id={"MAIN_COMPONENT"} style={{position: 'relative', opacity: 0}}>
            <Recommencer onClick={() => backStep()} > {t('results.home_confirm', 'Recommencer')} </Recommencer>
                <BackHomeIcon onClick={() => backStep()} />
                <ConcernView />
                <ProductsView />
                <FooterView />
            </MainViewContainer>
        </CSSTransition>


    )
}

const MainResultViewComponent = () => {
    return (<MainResultView />)
}

export default MainResultViewComponent