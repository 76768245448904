import React, { useState } from 'react'
import styled from 'styled-components'
import { MainButton, MyBInput } from '../Styles/Buttons'
import { SignInForm } from '../Styles/Containers'
import logo from '../Assets/LOGOMyBlendSquare.svg'
import { IMG } from '../Styles/Images'
import { useDispatch } from 'react-redux'
import * as ActionTypes from '../Constants/ActionsTypes'

const SubmitButton = styled(MainButton)`
        height: 68px;
        border-radius: 40px;
        font-size: 22px;
        min-width: 300px;
    `;

const MyBlendLogo = styled(IMG)`
    background-image : url(${logo});
    width: 124px;
    height: 124px;
    position: relative;
    `;

const SignIn = () => {
    const [name, setName] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [fail, setFail] = useState<string>("")
    const dispatch = useDispatch()

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if(name.toLowerCase() === 'admin' && password.toLowerCase() === 'admin'){
            dispatch({type: ActionTypes.SET_STEP, value: 0})
        } else {
            setFail('Wrong Password/name')
            setName("")
            setPassword("")
            setTimeout(() => {
                setFail("")
            }, 3000);
        }
    }

    return (
        <SignInForm onSubmit={handleSubmit}>
            <MyBlendLogo />
            <MyBInput
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name"
            />
            <MyBInput
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
            />
            <SubmitButton onClick={() => handleSubmit}> Submit</SubmitButton>
            <p>{fail}</p>
        </SignInForm>
    )
}

const SignInComponent = () => {
    return (<SignIn />)
}

export default SignInComponent