import {CBSKError,Range,Dictionary} from './defaults'

export interface CBSKWarning {
    name: string
    value: number
}

export function riseWarnings(warnings:Array<CBSKWarning>, config:Dictionary<Range>) : Array<CBSKError>{

    var rised : Array<CBSKError> = []
    let warningDict : Dictionary<number> = warnings.reduce((a,x) => ({...a, [x.name]: x.value}), {})
    function evaluate(param:string,up:CBSKError, down:CBSKError){
        let range = config['Lighting']
        let value = warningDict['Lighting']

        if (value < range.min) {
            rised.push(down)
        } else if (value > range.max) {
            rised.push(up)
        } else {
            return 0
        }
    }
    evaluate("Lighting",CBSKError.high_light,CBSKError.low_light)
    evaluate("Offset",CBSKError.too_far,CBSKError.too_close)
    evaluate("Rotation",CBSKError.too_left,CBSKError.too_right)
    evaluate("Camera angle",CBSKError.too_up,CBSKError.too_down)

    return rised
}
