import styled from 'styled-components'
import RotateIcon from '../Assets/RotateIcon.png'


export const AppStyled = styled.div<{ step: number }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.step === 3 || props.step === 4 ? 'stretch' : 'center'};
    min-width: 350px;
    min-height: ${props => props.step === 3 || props.step === 2 ? '100vh' : ''};
    overflow-y: auto;
    scroll-behavior: smooth;
`;
export const AppLandscape = styled.img`
    display: flex;
    position: relative;
    background-image: url(${RotateIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    width: 200px;
    height: 200px;
`;

export const NavBarStyled = styled.div<{ step: number }>`
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-around;
    width:100%;
    z-index: ${props => (props.step === 1 || props.step === 4) ? 10 : 1};
    background-color: white; 
    position: fixed;
`;

export const QuestionNavBar = styled.div`
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-around;
    width:100%;
    z-index: 1;
    background-color: white; 
    position: fixed;
`;

export const ComponentContainer = styled.div`
    display: inherit;
    flex-direction: inherit;
    align-items: center;
    background: inherit;
    position: relative;
`;

export const CameraViewComponent = styled(ComponentContainer)`
    position: fixed;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const QuestsViewContainer = styled(ComponentContainer)`
    overflow-y: scroll;
`;

export const ParagraphContainer = styled.div`
    display: flex;
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
`;

export const PopOver = styled.div`
    position: fixed;
    align-items: center;
    transition: 0.3s;
    max-width: 600px;
    width: 100vw;
    z-index: 4;
    background-color: white;
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 20px 20px 0px 0px;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    overflow: auto;
    bottom: -100%;

    @media (min-width: 768px) {
		height: 550px;
	}
`;

// const PopUpAnimation = keyframes`
//     0% {top: 150px; opacity: 1;}
//     70% {opacity:1;}
//     100% {top: 800px; opacity: 0;}
// `;

export const Mask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;
`;

export const Flash = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: white;
	transition: 0.2s;
	opacity: 0;
	top: 0px;
	left: 0px;
	z-index: 100;
    pointer-events: none;
`;

export const QuestContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 100px;
`;

export const AnswerContainer = styled.div<{ $isLong: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: ${props => !props.$isLong && "column"};
    flex-wrap: ${props => props.$isLong && "wrap"};
    gap: 12px;
    padding: 0px 16px;
    max-width: 600px;
    box-sizing: border-box;
    justify-content: center;
`

export const ProgressBarEmpty = styled.div`
    position: fixed;
    background-color: #EEECE7;
    height:4px;
    top: 64px;
    width: 100%;
    z-index: 2;
`;

export const ProgressBar = styled.div<{ step: number }>`
    position: fixed;
    height: 4px;
    width: ${props => props.step * 8.33 + 8.33 + '%'};
    background-color: #1B4175;
    top: 64px;
    transition-duration: 1s;
    z-index: 2;
`;

export const IconContainer = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NextQuestContainer = styled.div<{ questStep: boolean }>`
    background: rgba(255,255,255,0.45) 0% 0% no-repeat padding-box;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    height: 64px;
    position: fixed;
    bottom: 0;
    display: ${props => props.questStep ? 'flex' : 'none'};
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const SignInComponentContainer = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    top: 64px;
    flex-direction: column;
    justify-content: center;
`;

export const SignInForm = styled.form`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const Bubble = styled.div<{ color: string, isBig: boolean }>`
    width: ${props => props.isBig ? '60px' : '36px'};
    height: ${props => props.isBig ? '60px' : '36px'};

    width: ${props => props.isBig && window.innerWidth >= 768 ? '100px' : window.innerWidth > 768 ? '60px' : ''};
    height: ${props => props.isBig && window.innerWidth >= 768 ? '100px' : window.innerWidth > 768 ? '60px' : ''};
    background-color: white;
    color: ${props => props.color};
    z-index : 4;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`
/* eslint-disable no-useless-concat */
export const BubbleContent = styled.div<{ previousX: number, previousY: number, isInCarousel: boolean }>`
    position: ${props => !props.isInCarousel ? 'absolute' : 'relative'};
    top: ${props => !props.isInCarousel && window.innerWidth < 768 && 'calc(' + props.previousX + 'px + ' + 'calc(0% + 18px))'};
    left: ${props => !props.isInCarousel && window.innerWidth < 768 && 'calc(' + props.previousY + 'px + ' + 'calc(50% - 18px))'};
    top: ${props => !props.isInCarousel && window.innerWidth >= 768 && 'calc(' + props.previousX + 'px + ' + 'calc(50%))'};
    left: ${props => !props.isInCarousel && window.innerWidth >= 768 && 'calc(' + props.previousY + 'px + ' + 'calc(50%))'};
    transform: ${props => !props.isInCarousel ? 'rotate(82deg)' : ''};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

// export const BubbleContent = styled.div<{ radius: number, isInCarousel: boolean }>`
//     position: ${props => !props.isInCarousel ? 'absolute' : 'relative'};
//     top: ${props => !props.isInCarousel && window.innerWidth < 768 && 'calc(' + 150 * Math.cos(props.radius) + 'px + ' + 'calc(50% - 30px))'};
//     left: ${props => !props.isInCarousel && window.innerWidth < 768 && 'calc(' + 150 * Math.sin(props.radius) + 'px + ' + 'calc(50% - 18px))'};

//     top: ${props => !props.isInCarousel && window.innerWidth >= 768 && 'calc(' + 230 * Math.cos(props.radius) + 'px + ' + 'calc(50%))'};
//     left: ${props => !props.isInCarousel && window.innerWidth >= 768 && 'calc(' + 230 * Math.sin(props.radius) + 'px + ' + 'calc(50%))'};

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// `

export const ResultMainViewContainer = styled(ComponentContainer)`
    margin-top: 64px;
    overflow-y: scroll;
    width: 100%;
    position: relative;
`

export const HalfCircleWrapper = styled.div`
    width: 100px; 
    height: 100px;
    position: absolute;
    clip: rect(0px, 100px, 100px, 50px);
`
export const CarouselContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    @media (min-width: 768px) {
        margin-top: 80px;
    }
`

export const CarouselContainer = styled.div`
    margin-top: 200px;
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
`

export const ThreePreoccupationsBox = styled.div`
    display: flex;
`

export const ProductCellContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px;
    width: 100%;
`

export const ProductsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
    box-shadow: 0px 3px 13px grey;
    border-radius: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    background: white;
`

export const SmallProductCellContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TextProductCellContainer = styled(SmallProductCellContainer)`
    flex-direction: column;
    padding-left: 8px;
    padding-right: 20px;
    align-items: flex-start;
`

export const ProductDetailPopUp = styled.div`
    position: fixed;
    display: flex;
    left: 0;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    z-index: 4;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 20px 20px 0px 0px;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    overflow: auto;
    max-height: 500px;
    overscroll-behavior: none;
`

export const WhitePanel = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    width: 100%;
`

export const BottomBuyBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 64px;
    width: 100vw;
    background: rgba(255,255,255,0.45);
    backdrop-filter: blur(25px);
    bottom: 0;
    left: 0;
`

export const ConcernImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: 110px;
`

export const GradiantImageLeft = styled.div`
    position: absolute;
    height: 200px;
    top: 0;
    width: 45px;
    background: linear-gradient(to right,#f3f1f0,rgba(255,255,255,0));
    left: 50px;
    z-index: 2;
`

export const GradiantImageRight = styled.div`
    position: absolute;
    height: 200px;
    top: 0;
    width: 45px;
    background: linear-gradient(to left,#f3f1f0,rgba(255,255,255,0));
    right: 50px;
    z-index: 2;
`

export const WarningContainer = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 10;
    top: 90px;
`

export const WarningIconContainer = styled.div<{$isDisplayed: boolean}>`
    flex-direction: column;
    height: 32px;
    width: 32px;
    z-index: 10;
    position: absolute;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    display: flex;
    opacity: ${props => props.$isDisplayed ? 1 : 0};
`